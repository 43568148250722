import { render, staticRenderFns } from "./politica-de-envio-devoluciones.vue?vue&type=template&id=a2baef8e&lang=html"
import script from "./politica-de-envio-devoluciones.vue?vue&type=script&lang=js"
export * from "./politica-de-envio-devoluciones.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports