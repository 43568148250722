<template lang="html">
  <div id="cart-page" class="d-flex account-page">
    <div class="container main-box">

      <div class="row">
        <div class="col-12 px-2">
          <h1 class="mb-3 page-title">Mi carrito</h1>
        </div>

        <div class="col-lg px-2 col-menu">
          <div class="white-box">
            <div class="box-menu py-2">
              <!-- <p class="item">
                <router-link class="btn-menu" to="/mis-favoritos"><i class="fas fa-heart"></i> Mis favoritos</router-link>
              </p> -->
              <p class="item">
                <router-link class="btn-menu" to="/cart"><i class="fas fa-shopping-cart"></i> Mi carrito</router-link>
              </p>
              <!-- <p class="item">
                <router-link class="btn-menu" to="/mis-favoritos"><i class="fas fa-heart"></i> Mis favoritos</router-link>
              </p> -->
              <!-- <p class="item">
                <router-link class="btn-menu" to="/historial-de-compras"><i class="fas fa-history"></i> Historial de compras</router-link>
              </p> -->
            </div>
          </div>
        </div>

        <div class="col-lg px-2 col-cart-data">

          <!-- <div class="white-box"> -->
          <b-form class="white-box" @submit.prevent="">

            <!-- Paso 1 (Lista de productos agregados a carrito) -->
            <div class="col-12 pt-3 product-list-table" v-if="$route.params.step !== 'envio' && $route.params.step !== 'pago' && $route.params.step !== 'paqueteria'">
              <div class="col-12 px-0">
                <h5>Productos agregados al carrito</h5>

                <h6 class="mt-4 pb-4 f-w-400 text-warning" v-if="!form.products.length"><i class="fas fa-shopping-basket"></i> Ningun producto ha sido agregado al carrito</h6>
              </div>

              <div class="row row-products" v-for="(product, index) in form.products">
                <div class="col col-image">
                  <img :src="product.imageUrl">
                </div>

                <div class="col col-info">
                  <h5 class="txt-name">
                    <router-link target="_blank" :to="'/producto/'+product.id">{{ product.name }} <span v-if="product.namemodel_size != null">- {{ product.namemodel_size }} </span> <span v-if="product.namemodel_model != null">- {{ product.namemodel_model }} </span></router-link>
                  </h5>

                  <div class="row mx-0 align-items-center">
                    <div class="d-flex">
                      <h6 class="txt-sm text-muted">SKU: {{ product.sku }} <br><br><p>{{ product.shipfrom }}</p></h6>
                      
                    </div>
                    <div class="d-flex">
                      <div class="box-color-opt opt-sm">
                        <!-- <a class="color" title="Color x" v-bind:style="{ backgroundColor: '#e63003' }">{{ product.color }}</a> -->
                        <!-- <a class="color" :title="product.color">{{ product.color }}</a>
                        <a class="color" :title="product.size">{{ product.size }}</a> -->

                       
                      </div>
                    </div>
                  </div>
                  <hr class="mt-0 mb-1" />

                  <router-link class="link-sm text-muted" target="_blank" :to="'/producto/'+product.id">Ver producto</router-link> <span class="mx-2">|</span> <a class="link-sm text-danger" @click="removeCart(index)"><i class="far fa-trash-alt"></i> Quitar</a>
                </div>

                <div class="col col-price">
                  <p>$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }} MXN</p>

                  <span class="cross" style="color: #b3b2b2;font-size: 80%; margin-bottom: 0; text-decoration: line-through;" v-if="product.type_disc == 'descuento_envio' || product.type_disc == 'descuento'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.pricetotal) }} </span>
                  <br v-if="product.type_disc == 'descuento_envio' || product.type_disc == 'descuento'">
                  <!-- PRECIO FINAL CON DESCUENTO-->
                  <span v-if="product.type_disc == 'descuento_envio' || product.type_disc == 'descuento'" class="text-muted" style="font-size: 80%;">{{product.discount}} % de descuento</span>
                  <!-- ENVIO GRATIS-->
                  <span v-if="product.type_disc == 'descuento_envio' || product.type_disc == 'envio'" style="color: #e91f78;font-size: 80%;">Envío gratis</span>
                        

                </div>
                <div class="col col-num">
                  <input class="form-control" v-model.number="product.quantity" maxlength="2" placeholder="0"  @change="updateCart" @keypress="isNumber($event)">
                  <!-- <b-form-input v-model.number="product.quantity" type="number" min="1" :max="product.stock" placeholder="0" @change="updateCart" @keypress="isNumber($event)"/> -->
                </div>
              </div>
            </div>
            <!-- paso 1, Fin -->

            <!-- Paso 2 (Envio, Datos del usuario y datos de envío) -->
            <div class="col-12 py-4 px-4 user-data" v-if="$route.params.step == 'envio'">
                <h6>Datos de personales</h6>
                <hr class="c-hr" />

                <div class="custom-input _p">
                    <label class="col-form-label pt-0">Nombre:</label>
                    
                    <b-form-input id="user-name"  type="text" v-model="form.user.name" size="sm" required placeholder="Nombre"/>
                    <small style="color:red" v-if="validation.name">El campo "Nombre" es obligatorio</small>
                </div>

                <div class="custom-input _p">
                    <label class="col-form-label pt-0">Apellidos:</label>
                    
                    <b-form-input id="user-lastname"  type="text" v-model="form.user.lastname" size="sm" required placeholder="Apellidos"/>
                    <small style="color:red" v-if="validation.lastname">El campo "Apellidos" es obligatorio</small>
                </div>

                <div class="custom-input _p">
                    <label class="col-form-label pt-0">Correo electrónico:</label>
                   
                    <b-form-input id="user-email" type="text" v-model="form.user.email" size="sm" required placeholder="Correo electrónico"/>
                    <small style="color:red" v-if="validation.email">El campo "Correo electrónico" es obligatorio</small>
                </div>

                <div class="custom-input _p">
                    <label class="col-form-label pt-0">Teléfono:</label>
                    <b-form-input id="user-phone" type="text" v-model="form.user.phone" size="sm" required placeholder="Teléfono" maxlength="10"/>
                    <small style="color:red" v-if="validation.phone">El campo "Teléfono" es obligatorio</small>
                </div>


                <h6 class="mt-5">Información de envío</h6>
                <hr class="c-hr" />

                <!-- <div class="row mx-0 no-gutters">
                    <div class="col-md-12">
                        <b-form-group class="custom-input" label="Tipo de entrega:" label-for="i-entrega">
                            <v-select v-model="form.shippingMethod" :options="['Enviar a domicilio', 'Recoger en tienda']" placeholder="Tipo de entrega"/>
                        </b-form-group>
                    </div>
                </div> -->

                <div >
                    <div class="row mx-0 no-gutters">
                        <div class="col-md-7 col-lg-6">
                        <b-form-group class="custom-input" label="Calle:" label-for="i-calle">
                            <b-form-input id="i-calle" type="text" v-model="form.user.street" size="sm" placeholder="Calle donde vive" required @change="chageAddress()"/>
                            <small style="color:red" v-if="validation.street">El campo "Calle" es obligatorio</small>
                        </b-form-group>
                        </div>

                        <div class="col-md pl-md-3">
                        <b-form-group class="custom-input" label="Numero exterior:" label-for="i-num">
                            <b-form-input id="i-num" type="text" v-model="form.user.num_ext" size="sm" required @change="chageAddress()"/>
                            <small style="color:red" v-if="validation.num_ext">El campo "Numero exterior" es obligatorio</small>
                        </b-form-group>
                        </div>

                        <div class="col-md pl-md-3">
                        <b-form-group class="custom-input" label="Numero interior:" label-for="i-num">
                            <b-form-input id="i-num" type="text" v-model="form.user.num_int" size="sm" @change="chageAddress()"/>
                        </b-form-group>
                        </div>
                    </div>

                    <div class="row mx-0 no-gutters">
                        <div class="col-md-8">
                        <b-form-group class="custom-input" label="Colonia:" label-for="i-colonia">
                            <b-form-input id="i-colonia" type="text" v-model="form.user.neighborhood" size="sm" required placeholder="Escriba su colonia" @change="chageAddress()"/>
                            <small style="color:red" v-if="validation.neighborhood">El campo "Colonia" es obligatorio</small>
                        </b-form-group>
                        </div>

                        <div class="col-md pl-md-3">
                        <b-form-group class="custom-input" label="Código postal:" label-for="i-cp">
                            <b-form-input id="i-cp" type="text" v-model="form.user.zipcode" size="sm" required placeholder="Escriba su código postal" minlength="5" maxlength="5" @change="chageAddress()"/>
                            <small style="color:red" v-if="validation.zipcode">El campo "Código postal" es obligatorio</small>
                        </b-form-group>
                        </div>
                    </div>


                    <div class="row mx-0 no-gutters">
                        <div class="col-md-6 pr-md-3">
                        <b-form-group class="custom-input" label="Estado:" label-for="i-estado">
                            <v-select v-model="form.user.state_id" :options="states" label="name" index="id" @change="getTowns"/>
                            <small style="color:red" v-if="validation.state_id">El campo "Estado" es obligatorio, selecciona un opción</small>
                      </b-form-group>
                        </div>

                        <div class="col-md-6">
                        <b-form-group class="custom-input" label="Ciudad:" label-for="i-ciudad">
                            <v-select v-model="form.user.town_id" :options="towns" label="name" index="id" @change="chageAddress()"/>
                            <small style="color:red" v-if="validation.town_id">El campo "Ciudad" es obligatorio, selecciona un opción</small>
                      </b-form-group>
                        </div>
                    </div>

                    <div class="custom-input _p">
                        <label class="col-form-label pt-0">Referencias:</label>
                        <b-form-textarea id="user-phone" type="text" v-model="form.user.address_references" size="sm" required placeholder="Referencias" maxlength="30" @change="chageAddress()"/>
                    </div>

                </div>
            </div>
            <!-- Paso 2, fin  -->

            <!-- Paso 3 (envio) -->
            <div class="col-12 py-4 px-4 user-data" v-if="$route.params.step == 'paqueteria'">
              <!-- <h6>Información de envio</h6> -->
              <hr class="c-hr" />

              <div class="custom-input _p">
                <label class="col-form-label pt-0"><b>Selecciona una forma de envio para tu(s) producto(s) a la direccion siguiente:</b></label>
                <br>
                <span>{{ form.user.street }}, {{ form.user.num_ext }}, {{ form.user.num_int }}, {{ form.user.neighborhood }}, {{ form.user.zipcode }}, {{ form.user.town }}, {{ form.user.state }}</span>
              </div>

              <div class="mb-4 payment-options-container">
                <div class="container">
                    <div class="row">
                        <div class="col-12" v-for="(pck,indx) in deliveries" :key="indx">
                          <div>
                            <p><b>Productos a enviar de <span style="color: #009d37;">{{ pck.afiliate }}</span> :</b></p>
                            <span v-for="(pro,indxpro) in pck.products" :key="indxpro+'p'">{{ pro.sku }} - {{ pro.name }} <span v-if="pro.namemodel_size != null">- {{ pro.namemodel_size }} </span> <span v-if="pro.namemodel_model != null">- {{ pro.namemodel_model }} </span> <span v-if="pro.free_shipping == 1" style="color:#009d37"><b>(Envío gratis)</b></span> <br></span>
                            <br>
                          </div>
                          <div class="row">
                            <div class="col col-box" v-for="(deliv,indxde) in pck.deliveries" :key="indxde+'d'" @click="changeCourier(indx,indxde)">
                  
                              <div class="box" v-bind:class="{ active: form.couriers[indx]['serviceType'] == deliv.serviceType }">
                                  <h6 style="height: 25px;">{{deliv.courier}}</h6>
                                  <p class="icons">
                                      <img v-if="deliv.courier == '99minutos'" src="https://anlesa.com/public/images/pages/cart/logo_99minutos.png" width="150px">
                                      <img v-else-if="deliv.courier == 'Estafeta'" src="https://anlesa.com/public/images/pages/cart/logo_estafeta.png" width="150px">
                                      <img v-else-if="deliv.courier == 'FedEx'" src="https://anlesa.com/public/images/pages/cart/logo_fedex.png" width="120px">
                                  </p>
                                  <p>{{ deliv.serviceName }} </p>
                                  <p>$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(deliv.amount) }} {{ deliv.currency }}</p>
                                  <p v-if="deliv.expectedDelivery.length > 0">{{ deliv.expectedDelivery[0] }} dias aproximados después de la recolección con el emprendedor</p>
                              </div>
                            </div>
                          </div>
                          <hr>
                        </div>
                    </div>
                </div>
              
                  
                 
                  
              </div>

              
            </div>


            <!-- Paso 3, fin  -->

            <!-- Paso 4 (pago) -->
            <div class="col-12 py-4 px-4 user-data" v-if="$route.params.step == 'pago'">
              <h6>Información de pago</h6>
              <hr class="c-hr" />

              <div class="custom-input _p">
                <label class="col-form-label pt-0">Seleccione una forma de pago:</label>
              </div>

              <div class="row mb-4 payment-options-container">
                 <div class="col col-box" @click="changePaymentMe('tarjetas')">
                  <div class="box" v-bind:class="{ active: form.tipoPago == 'tarjetas' }">
                    <h6>Tarjeta de crédito o débito</h6>
                    <p class="icons">
                      <i class="fab fa-cc-visa"></i>
                      <i class="fab fa-cc-mastercard"></i>
                      <i class="fab fa-cc-amex"></i>
                    </p>
                  </div>
                </div> 

                <div class="col col-box" @click="changePaymentMe('transferencia')" v-if="form.user.email == 'richardsustam23@gmail.com'">
                  <div class="box" v-bind:class="{ active: form.tipoPago == 'transferencia' }">
                    <h6>Deposito o transferencia</h6>
                    <p class="icons">
                      <i class="fas fa-money-check-alt"></i>
                      <i class="fas fa-money-bill"></i>
                    </p>
                  </div>
                </div> 

                <!-- <div class="col col-box" @click="changePaymentMe('paypal')">
                  <div class="box" v-bind:class="{ active: form.tipoPago == 'paypal' }">
                    <h6>Pago a traves de Paypal</h6>
                    <p class="icons">
                      <i class="fab fa-paypal"></i>
                    </p>
                  </div>
                </div> -->
              </div>

              <!-- Pago 1, con tarjetas -->
              <div class="row mx-0 no-gutters" v-if="form.tipoPago == 'tarjetas'">
                <div class="col-12">
                    <div class="alert alert-primary" role="alert">
                        Sus datos de tarjeta de debito o de credito no son almacenados por nuestro sistema y son procesados de manera segura y encriptada. En cada pago sera necesario agregar nuevamente estos datos.
                    </div>

                    <label class="mt-3">Detalles de tarjeta:</label>
                    <div id="cardElement"></div>
                    <small class="form text text-muted" id="cardErrors" role="alert"></small>
                </div>
              </div>
              <!--  -->

              <!-- Pago 2, con transferencia bancaria -->
              <div class="col-12 text-center" v-if="form.tipoPago == 'transferencia'">
                <img src="public/images/hsbc.png" width="100">
                <p><strong>BANCO:</strong> HSBC</p>
                <p><strong>TITULAR:</strong> SUM SHOP, S.A.P.I DE C.V.</p>
                <p><strong>CLABE INTERBANCARIA:</strong> 021180040664977441</p>
                <p><strong>NÚMERO DE CUENTA:</strong>4066497744</p>
                <hr />
                <p>
                  Favor de enviarnos el comprobante de pago al siguiente correo: <br />
                  <strong>pago@anlesa.com</strong>
                </p>
              </div>
              <!--  -->

              <!-- Pago 3, con paypal -->
              <div class="col-12 text-center" v-if="form.tipoPago == 'paypal'">
                <p>Depósito por medio de paypal.</p>

                <hr />
                <p>
                  Favor de enviarnos el comprobante de pago al siguiente correo: <br />
                  <strong>pago@anlesa.com</strong>
                </p>
              </div>

              <br><br>
              <div class="col-12 text-center">
                <b-form-checkbox v-model="form.tos" name="usertype" :value="true" :unchecked-value="false" required>
                
                  Acepto y estoy de acuerdo con los 
                  <router-link style="color: #6b6b6b;" target="_blank" to="/terminos-y-condiciones-de-uso"><u>Términos y condiciones de uso de ANLESA</u></router-link>, 
                    la <router-link style="color: #6b6b6b;" target="_blank" to="/politica-de-envio-devoluciones"><u>Política de envió y devoluciones de ANLESA</u></router-link>, 
                    y, los <router-link style="color: #6b6b6b;" target="_blank" to="/terminos-y-condiciones-marketplace"><u>Términos y Condiciones Marketplace de ANLESA</u></router-link>
               
                </b-form-checkbox>
              </div>
              <!--  -->
            </div>
            <!-- Paso 3, fin -->
          </b-form>

          <div class="row mx-0 py-3 px-lg-0 no-gutters row-total">
            <div class="col-lg-5">
              <router-link class="btn btn-outline-danger btn-sm btn-action" :to="'/cart/' + form.prev" v-if="form.prev != null">Anterior</router-link>
              <router-link class="btn btn-outline-danger btn-sm btn-action" :to="'/cart/' + form.next" v-if="form.next && canbuy">Siguiente</router-link>
              <!-- <button class="btn btn-danger btn-sm btn-action" v-if="form.paying && form.tipoPago == 'transferencia'" @click="createOrder()" :disabled="order_id">Confirmar pedido</button> -->
              <button class="btn btn-danger btn-sm btn-action" v-if="form.paying && form.tipoPago == 'transferencia'" @click="pagar()" :disabled="desactivar || order_id">Confirmar pedido</button>
              <button class="btn btn-danger btn-sm btn-action" v-if="form.paying && form.tipoPago == 'tarjetas'" @click="pagar()" :disabled="desactivar">Pagar</button>
            </div>

            <div class="col-lg-7 text-right">
              <h6><span>Subtotal: </span> ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(subtotal) }}</h6>
              
              <h6 v-if="$route.params.step != 'paqueteria' && $route.params.step != 'pago'"><span>Costo y tiempo de envío: </span> Por definir</h6>
              <div v-else>
                <h6 v-for="(c,inx) in form.couriers" :key="inx"><span >Envío paquete {{ inx + 1 }}: </span> {{ c.courier }} - {{ c.serviceName }}: ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(c.envio) }} <br></h6>
                
               </div>

              <!-- <h6><span>Tiempo de entrega:</span> Entrega de 48 a 72 hrs.</h6> -->
              <h5><span>Total: </span> ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(total) }}</h5>
            </div>
          </div>
        </div>
      </div>

      <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        <div v-html="modal.msg"></div>
        <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
          <div class="txt-description" v-if="stock < 1">Entrega de 48 a 72 hrs</div>
          <b-button class="btn btn-success" slot="button" @click="toHome">Regresar a Home <i class="color-white fas fa-home"></i></b-button>
        </div>
      </sweet-modal>

    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        products: [],

        user: {
          "email": '',
          "name": '',
          "lastname": '',
          "phone": '',
          "street": '',
          "num_ext": '',
          "num_int": '',
          "neighborhood":'',
          "zipcode": null,
          "state_id": null,
          "town_id": null,
          "state": null,
          "town": null,

        },
        
        prev: '',
        next: '',
        paying: false,

        tipoPago: '',
        subtotal: 0,
        total: 0,
        commision:0,
        envio: 0,
        shippingMethod: 'Enviar a domicilio',

        couriers:[],
        courier:{
          serviceType:null,
          serviceName:null,
          courier:null
        },
        tkn_sship:null,

        tos:false
        
      },

      modal:{
        msg:'',
        icon:'',
        block:false,
      },

      userLogin: false,
      states: [],
      towns: [],
      order_id: null,
      paqueteria: 0,

      stripe: {},
      elements: {},
      cardElement: {},

      desactivar: false,
      deliveries:[],
      validation:{
          name:false,
          lastname:false,
          email:false,
          phone:false,
          street:false,
          num_ext:false,
          neighborhood:false,
          zipcode:false,
          state_id:false,
          town_id:false,
      },
      canbuy:true
    }
  },

  computed: {
    subtotal() {
     var subtotal = this.form.products.reduce(function (subtotal, item) {
        return subtotal + (item.price * item.quantity)
      },0);
      var commision = this.form.products.reduce(function (sum, item) {
        return sum +  (item.commision * item.quantity)
      },0);
      var commision_ship = 0;
      for (let z = 0; z < this.form.couriers.length; z++) {
        if (this.form.couriers[z]['courier'] != null) {
          commision_ship += this.form.couriers[z]['discount_ship'];
        }
        
      }
      commision = commision + commision_ship;

      
      var envio = 0;
      if (this.form.couriers.length > 0) {
          for (let x = 0; x < this.form.couriers.length; x++) {
              if (this.form.couriers[x]['serviceType'] != null) {
                envio+= this.form.couriers[x]['envio']
              } 
            
          }
      }
      this.form.envio = envio;
      
      this.form.commision = parseFloat(commision);
      return this.form.subtotal = subtotal;
    },

    total() {
      var subtotal = this.form.products.reduce(function (sum, item) {
        return sum +  (item.price * item.quantity)
      },0);

      var commision = this.form.products.reduce(function (sum, item) {
        return sum +  (item.commision * item.quantity)
      },0);
      var commision_ship = 0;
      for (let z = 0; z < this.form.couriers.length; z++) {
        if (this.form.couriers[z]['courier'] != null) {
          commision_ship += this.form.couriers[z]['discount_ship'];
        }
        
      }
      commision = commision + commision_ship;

        /*if(this.form.shippingMethod == 'recoger en tienda'){
            this.paqueteria = this.form.envio;
            this.form.envio = 0;
        }

        if(this.form.shippingMethod == 'enviar a domicilio'){
            this.form.envio = this.paqueteria;
        }*/
        var checkserv = 0;
        for (let x = 0; x < this.form.products.length; x++) {
          if (this.form.products[x]['type'] == 'producto') {
            checkserv++;
          }
          
        }
       
      
      var envio = 0;
      if (this.form.couriers.length > 0) {
          for (let x = 0; x < this.form.couriers.length; x++) {
              if (this.form.couriers[x]['serviceType'] != null) {
                envio+= this.form.couriers[x]['envio']
              } 
            
          }
      }
      this.form.envio = envio;
      /*if (checkserv > 0) {
        this.form.envio = 150;
      }
      else{
        this.form.envio = 0;
      }*/
      
      var grandTotal  = parseFloat(subtotal) + parseFloat(this.form.envio);
      this.form.commision = parseFloat(commision);
      return this.form.total = grandTotal;
    }
  },

  methods: {
    isNumber: function(evt) {
				evt = (evt) ? evt : window.event;
				var charCode = (evt.which) ? evt.which : evt.keyCode;
				if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
					evt.preventDefault();
				} else {
					return true;
				}
			},
    changePaymentMe(tipo){
      this.form.tipoPago = tipo;
      if(tipo == "tarjetas"){
        setTimeout(this.loadStripe, 50);
      }
    },

    pagar(){
      if (this.form.tos == false) {
          alert('Debes de aceptar los Términos y condiciones de uso de ANLESA, la Política de envió y devoluciones de ANLESA, y los Términos y Condiciones Marketplace de ANLESA');
          return;
      }
      else{
          if(this.form.tipoPago == "transferencia"){
            this.desactivar = true;
            this.createOrder();
          }

          if(this.form.tipoPago == "tarjetas"){
            this.desactivar = true;
            this.payStripe();
          }
      }
    },

    getSteps(){
     
      var step = this.$route.params.step;
      this.form.paying = false;

      if(step == 'pago'){ // Paso 4
        this.checkStockCart();
        var checkcourier = false;
        for (let x = 0; x < this.form.couriers.length; x++) {
          if (this.form.couriers[x]['courier'] == null) {
            checkcourier = true;
          }
        }

        if (checkcourier == true) {
          
            alert('Debes seleccionar una paqueteria');
            this.$router.push('/cart/paqueteria');
            this.form.prev = 'envio';
            this.form.next = 'pago';

            return;
        }
        else{
          this.form.prev = 'paqueteria';
          this.form.next = null;
          this.form.paying = true;
        }
        
      }
      else if(step == 'paqueteria'){ // Paso 3
        this.checkStockCart();
        this.validation = {
          name:false,
          lastname:false,
          email:false,
          phone:false,
          street:false,
          num_ext:false,
          neighborhood:false,
          zipcode:false,
          state_id:false,
          town_id:false,
      };

        if (this.form.user.name == null || this.form.user.name == '' ) {
            this.validation.name = true;
            //alert('El campo "Nombre" es obligatorio');
            this.form.prev = '';
            this.form.next = 'paqueteria';
            this.$router.push('/cart/envio');
            
            return;
        }
        else if (this.form.user.lastname == null || this.form.user.lastname == '' ) {
            this.validation.lastname = true;
            //alert('El campo "Apellidos" es obligatorio');
            this.form.prev = '';
            this.form.next = 'paqueteria';
            this.$router.push('/cart/envio');
            return;
        }
        else if (this.form.user.email == null || this.form.user.email == '' ) {
            this.validation.email = true;
            //alert('El campo "Correo electrónico" es obligatorio');
            this.form.prev = '';
            this.form.next = 'paqueteria';
            this.$router.push('/cart/envio');
            return;
        }
        else if (this.form.user.phone == null || this.form.user.phone == '' ) {
            this.validation.phone = true;
            //alert('El campo "Teléfono" es obligatorio');
            this.form.prev = '';
            this.form.next = 'paqueteria';
            this.$router.push('/cart/envio');
            return;
        }
        else if (this.form.user.street == null || this.form.user.street == '' ) {
          this.validation.street = true;
            //alert('El campo "Calle" es obligatorio');
            this.form.prev = '';
            this.form.next = 'paqueteria';
            this.$router.push('/cart/envio');
            return;
        }
        else if (this.form.user.num_ext == null || this.form.user.num_ext == '' ) {
          this.validation.num_ext = true;
           // alert('El campo "Numero exterior" es obligatorio');
            this.form.prev = '';
            this.form.next = 'paqueteria';
            this.$router.push('/cart/envio');
            return;
        }
        else if (this.form.user.neighborhood == null || this.form.user.neighborhood == '' ) {
            this.validation.neighborhood = true;
            //alert('El campo "Colonia" es obligatorio');
            this.form.prev = '';
            this.form.next = 'paqueteria';
            this.$router.push('/cart/envio');
            return;
        }
        else if (this.form.user.zipcode == null || this.form.user.zipcode == '' ) {
            this.validation.zipcode = true;
            //alert('El campo "Código postal" es obligatorio');
            this.form.prev = '';
            this.form.next = 'paqueteria';
            this.$router.push('/cart/envio');
            return;
        }
        else if (this.form.user.state_id == null || this.form.user.state_id == '' ) {
            this.validation.state_id = true;
            //alert('El campo "Estado" es obligatorio, selecciona un opción');
            this.form.prev = '';
            this.form.next = 'paqueteria';
            this.$router.push('/cart/envio');
            return;
        }
        else if (this.form.user.town_id == null || this.form.user.town_id == '' ) {
            this.validation.town_id = true;
            //alert('El campo "Ciudad" es obligatorio, selecciona un opción');
            this.form.prev = '';
            this.form.next = 'paqueteria';
            this.$router.push('/cart/envio');
            return;
        }
        else{
        
          this.form.prev = 'envio';
          this.form.next = 'pago';
          this.getDistance();
        }
        
      }
      else if(step == 'envio'){ // Paso 2
        this.checkStockCart();
        this.form.prev = '';
        this.form.next = 'paqueteria';

      }
      else{ // Paso 1
        this.form.prev = null;

        if(this.form.products.length){
          this.form.next = 'envio';
        }
      }
    },

    getStates(){
      axios.get(tools.url('/api/states')).then((response)=>{
        this.states = response.data;
      }).catch((error)=>{
         console.log(error);
      });
    },

    getTowns(){
      if(this.form.user.state_id){
        axios.get(tools.url('/api/towns/' + this.form.user.state_id)).then((response)=>{
          this.towns = response.data;
        }).catch((error)=>{
          console.log(error);
        });
      }
    },

    getInfo(){
      this.form.user = {
        //Datos personales
        id: this.$root.user.id,
        name: this.$root.user.name,
        lastname: this.$root.user.lastname,
        email: this.$root.user.email,
        phone: this.$root.user.phone,

        /*street: null,
        num_ext: null,
        num_int: null,
        neighborhood: null,
        zipcode: null,
        state_id: null,
        town_id: null,
        address_references: null,*/
        //street: this.$root.user.address.street,
        //num_ext: this.$root.user.address.num_ext,
        //num_int: this.$root.user.address.num_int,
        //neighborhood: this.$root.user.address.neighborhood,
        //zipcode: this.$root.user.address.zipcode,
        //state_id: this.$root.user.address.state_id,
        //town_id: this.$root.user.address.town_id,
      };

     

      this.getTowns();
    },

    getCart: function(){


      var storage = localStorage.getItem('cart');
      if(storage != null){
        this.modal.block = true;
        this.modal.icon = '';
        this.modal.msg = 'Cargando, por favor espere...';
        this.$refs.modal.open();

        var products = JSON.parse(storage);
        axios.post(tools.url('/api/cart'),{ products:products }).then((response)=>{
          this.form.products = response.data.cart;
          this.canbuy = response.data.canbuy;
          if(response.data.canbuy == false){
            this.modal.block = false;
            this.modal.icon = 'warning';
            this.modal.msg = response.data.msg;

            var newproducs = [];
              var products_new = response.data.cart;
              for (let x = 0; x < products_new.length; x++) {
                  if(products_new[x]['canbuy'] == true){
                      newproducs.push(products_new[x]);
                  }
              } 
              this.form.products = newproducs;
              this.updateCartFail();
              
          }
          else{
            this.modal.block = false;
            this.$refs.modal.close();
          }
          //this.form.envio = response.data.envio;
          this.getSteps();
          

        }).catch((error)=>{
          console.log(error);
          this.getSteps();
          this.modal.block = false;
            this.$refs.modal.close();
        });
      }
    },
    checkStockCart: function(){
        var storage = localStorage.getItem('cart');
        if(storage != null){
          
          var products = JSON.parse(storage);
          axios.post(tools.url('/api/checkstockcart'),{ products:products }).then((response)=>{
            this.canbuy = response.data.canbuy;
            if(response.data.canbuy == false){
             
              this.modal.block = false;
              this.modal.icon = 'warning';
              this.modal.msg = response.data.msg;
              this.$refs.modal.open();
              //this.form.products = response.data.cart;
              var newproducs = [];
              var products_new = response.data.cart;
              for (let x = 0; x < products_new.length; x++) {
                  if(products_new[x]['canbuy'] == true){
                      newproducs.push(products_new[x]);
                  }
              } 
              this.form.products = newproducs;
              this.updateCartFail();

              this.$router.push('/cart');
            }
            
          }).catch((error)=>{
          });
        }
    },

    getDistance: function(){
        this.modal.block = true;
        this.modal.icon = '';
        this.modal.msg = 'Cargando, por favor espere...';
        this.$refs.modal.open();

        axios.post(tools.url('/api/distance'),this.form).then((response)=>{
            this.deliveries = response.data.deliveries;
            this.form.tkn_sship = response.data.tkn_sship;
            this.modal.block = false;
            this.$refs.modal.close();

            
            var new_couriers = [];
            for (let z = 0; z < this.deliveries.length; z++) {
                var aux = {
                    'serviceType':null,
                    'serviceName':null,
                    'courier':null,
                    'envio':null,
                    'to_users_id':null,
                    'afiliate':null,
                    'discount_ship':null,
                };  
                new_couriers.push(aux);
            }
            this.form.couriers = new_couriers;
            //this.paqueteria = response.data.price;
        }).catch((error)=>{
          this.modal.icon = 'error';
            this.modal.block = false;
            this.modal.msg = error.response.data.msg;
            
            this.form.prev = '';
            this.form.next = 'paqueteria';
            this.$router.push('/cart/envio');
        });
    },

    removeCart: function(index) {
        this.$delete(this.form.products, index)
        this.updateCart();
    },

    updateCart(){
        var cart = [];
        var quantity_all = 0;
        this.form.products.forEach(function(val, index, array){
          var info = { id:val.id, quantity: val.quantity, modelo: val.modelo };
          cart.push(info);

          quantity_all += val.quantity;
        });
        this.$root.cartCount = quantity_all;
        localStorage.setItem("cart",JSON.stringify(cart));
        

        const now = new Date();
        const item = {timestamp: now.getTime()};
        localStorage.setItem('created_cart', JSON.stringify(item));
        this.getCart();
    },
    updateCartFail(){
      var cart = [];
        var quantity_all = 0;
        console.log(this.form.products);
        this.form.products.forEach(function(val, index, array){
          var info = { id:val.id, quantity: val.quantity, modelo: val.modelo };
          cart.push(info);

          quantity_all += val.quantity;
        });
        this.$root.cartCount = quantity_all;
        localStorage.setItem("cart",JSON.stringify(cart));
        

        const now = new Date();
        const item = {timestamp: now.getTime()};
        localStorage.setItem('created_cart', JSON.stringify(item));
        
        var storage = localStorage.getItem('cart');
        if(storage != null){
          var products = JSON.parse(storage);
          axios.post(tools.url('/api/cart'),{ products:products }).then((response)=>{
            this.form.products = response.data.cart;
          }).catch((error)=>{
            
          });
        }

    },

    createOrder: function(){
        this.modal.block = true;
        this.modal.icon = '';
        this.modal.msg = 'Procesando, por favor espere...';
        this.$refs.modal.open();

        axios.post(tools.url('/api/order/store'),{ order:this.form, products:this.form.products, user:this.form.user, userLogin:this.$root.logged }).then((response)=>{
            if(response.data.type === "success"){
                this.order_id = response.data.order_id;
                localStorage.removeItem('cart');
                this.$root.cartCount = 0;
                //mensaje
                this.modal.icon = "success";
                this.modal.msg = "TU PEDIDO HA SIDO EXITOSO!<br/> Número de pedido:<b> MX000"+response.data.order_id+"</b>. Por favor de enviar su comprobante de pago a <b>pago@anlesa.com</b>";
                this.modal.block = true;
                

            }else{
              this.desactivar = false;
                this.$refs.modal.close();
                alert(response.data.error);
            }
            this.desactivar = false;
        }).catch((error)=>{
          this.desactivar = false;
            console.log(error);
            this.modal.icon="error";
            this.modal.msg = "Fallo al realizar el pedido., Verifique su internet y recargue la página";
            this.modal.block = false;
            
        });
    },

    loadStripe(){
      //sandbox key
      this.stripe = Stripe('pk_live_51LZfLsKgxTsOar06OpdR9z3Cgco21fRRaZBJFs027A214ryrWVXCLhoVEWYJpsgOWkemo4sDv9Ysa5hGf8be7aYc00FIJOeKnO');
      this.elements = this.stripe.elements({locale: 'es'});
      this.cardElement = this.elements.create('card', {hidePostalCode: true});

      this.cardElement.mount("#cardElement");
      this.cardElement.addEventListener('change', ({ error }) => {
          const displayError = document.getElementById('cardErrors');
          if (error) {
            displayError.textContent = error.message;
          } else {
            displayError.textContent = '';
          }
      });
    },

    payStripe(){
      this.modal.block = true;
      this.modal.icon = '';
      this.modal.msg = 'Validando el metodo de pago. por favor espere...';
      this.$refs.modal.open();

      this.stripe.createPaymentMethod({
          type: 'card',
          card: this.cardElement,
          billing_details: { name: this.form.user.name+' '+this.form.user.lastname, email: this.form.user.email }
        }).then((result)=>{
          if (result.error) {
            alert('Ocurrió un error con su tarjeta');
            this.modal.block = false;
            this.$refs.modal.close();
            this.desactivar = false;
          } else {
            const data  = { payment_method_id: result.paymentMethod.id, total: this.form.total, name: this.form.name, email: this.form.email };
            axios.post(tools.url('/api/stripe/Installments'),data).then((response)=>{
                this.form.payment_intent_id = response.data.intent_id;
                this.pagarStripe();
              });
          }
      });
    },

    pagarStripe: function(){
      this.modal.block = true;
      this.modal.icon = '';
      this.modal.msg = 'Generando su pedido por favor espere...';
      //this.$refs.modal.open();

      axios.post(tools.url('/api/stripe/pay'),{  order:this.form, products:this.form.products, user:this.form.user, userLogin:this.$root.logged }).then((response)=>{
          if(response.data.type == "success"){
            this.order_id = response.data.order_id;
            localStorage.removeItem('cart');
            this.$root.cartCount = 0;
            //mensaje
            this.modal.block = false;
            this.modal.icon = "success";
            this.modal.msg = "TU PEDIDO HA SIDO EXITOSO!<br/> Número de pedido: <b>MX000"+response.data.order_id+"</b>.";
            
            this.desactivar = false;

          }else{
            if(response.data.message == 'Your card has insufficient funds.'){
              this.modal.icon = "error";
              this.modal.msg = 'Tu tarjeta no tiene suficientes fondos';
              this.modal.block = false;
              this.desactivar = false;
            }
            else{
              this.modal.icon = "error";
              this.modal.msg = response.data.message;
              this.modal.block = false;
              this.desactivar = false;
            }
          }
          this.desactivar = false;
      }).catch((error)=>{
        this.$refs.modal.close();
        alert("ERROR: No se pudo realizar el pedido.");
        this.desactivar = false;
      });
    },
    toHome(){
      this.modal.block = false;
      this.$refs.modal.close(); 
      this.$router.push('/');
    },
    changeCourier(devindx,indx){
      console.log(devindx,indx);
     
        this.form.couriers[devindx]['serviceType'] = this.deliveries[devindx]['deliveries'][indx]['serviceType'];
        this.form.couriers[devindx]['courier'] = this.deliveries[devindx]['deliveries'][indx]['courier'];
        this.form.couriers[devindx]['serviceName'] = this.deliveries[devindx]['deliveries'][indx]['serviceName'];
        
        this.form.couriers[devindx]['envio'] = this.deliveries[devindx]['deliveries'][indx]['amount'];
        this.form.couriers[devindx]['to_users_id'] = this.deliveries[devindx]['to_users_id'];
        this.form.couriers[devindx]['afiliate'] = this.deliveries[devindx]['afiliate'];
        this.form.couriers[devindx]['discount_ship'] = this.deliveries[devindx]['deliveries'][indx]['discount_ship'];
        this.form.couriers[devindx]['deliveryTimestamp'] = this.deliveries[devindx]['deliveries'][indx]['deliveryTimestamp'];
    },
    chageAddress(){
      var address = {
          'street':this.form.user.street,
          'num_ext':this.form.user.num_ext,
          'num_int':this.form.user.num_int,
          'neighborhood':this.form.user.neighborhood,
          'zipcode':this.form.user.zipcode,
          'state_id':this.form.user.state_id,
          'town_id':this.form.user.town_id,
          'address_references':this.form.user.address_references,
      };
      sessionStorage.setItem("cart_address",JSON.stringify(address));
    }
  },
  mounted(){
    this.getCart();
  },
  beforeMount(){
    if(this.$route.params.step){
      console.log('asdasdasd');
        this.$router.push('/cart');
    }
    else{
      console.log('aeeeeeeeeeee');
      this.getSteps();
    }
    
    this.getStates();
   

    if(this.$root.logged){
      this.getInfo();
    }

    var storage = sessionStorage.getItem('cart_address');
      var addresssession = JSON.parse(storage);
      
      this.form.user.street = addresssession.street;
      this.form.user.num_ext = addresssession.num_ext;
      this.form.user.num_int = addresssession.num_int;
      this.form.user.neighborhood = addresssession.neighborhood;
      this.form.user.zipcode = addresssession.zipcode;
      this.form.user.state_id = addresssession.state_id;
      this.form.user.town_id = addresssession.town_id;
      this.form.user.address_references = addresssession.address_references;


  },

  watch: {
    '$route.params.step': function () {
      this.getSteps();
    },

    "$root.logged": function(v) {
      if(v == true){
        this.getInfo();
      }
    },
    'form.user.state_id':function(){
        var index = null;
        for (let x = 0; x < this.states.length; x++) {
            if (this.states[x]['id'] == this.form.user.state_id) {
              index = x;
            }
        }
        if (index != null) {
          this.form.user.state = this.states[index]['name'];
        }
    },
    'form.user.town_id':function(){
        var index = null;
        for (let x = 0; x < this.towns.length; x++) {
            if (this.towns[x]['id'] == this.form.user.town_id) {
              index = x;
            }
        }
        if (index != null) {
          this.form.user.town = this.towns[index]['name'];
        }
    }
  }
}
</script>

<style>
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>
