<template lang="html">
  <div id="article-page">

    <div class="container content-section">
      <!-- <div class="row">
        <div class="col-12 col-title">
          <h5 class="title-s-1 txt-blue">Comunicados</h5>
        </div>
      </div> -->

      <div class="row">
        <!-- Artículo actual -->
        <div class="col-lg-8 col-article">
          <div class="mb-3 box-image">
            <img :src="noticia.imageUrl">
          </div>
          <h1 class="title">{{ noticia.title }}</h1>
          <div class="descr" v-html="noticia.body"></div>
        </div>
        <!--  -->

        <!-- Artículos -->
        <div class="col-lg-4 col-articles">
          <div class="row">

            <div class="col-12 px-0 article-sample-1" v-for="(article, arInx) in blogs" :key="'ar-'+arInx">
              <router-link class="t-250 placed-backg article-link" v-bind:style="{ backgroundImage: 'url('+article.imageUrl+')' }" :to="'/blog/'+article.id">
                <img class="empty" src="public/images/shared/empty.png">

                <div class="inside">
                  <div class="t-250 show">Ver más</div>
                  <div class="shadow"></div>
                  <div class="box-txt">
                    <h5>{{article.title}}</h5>


                    <!-- <div class="descr">
                      <p>
                        Descripción breve del comunicado para pactar el interés del usuario.
                      </p>
                    </div> -->
                  </div>
                </div>
              </router-link>
            </div>

          </div>
        </div>
        <!--  -->
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data(){
    return{
      noticia: {
        id: 5,
        imageUrl: 'public/images/pages/blog/article-1.jpg',
        title: 'Joyería Sostenible: Lo Mejor de la Moda Ética para Este Año',
        body: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eu lobortis elit. Praesent ut urna hendrerit, lobortis tortor at, vehicula massa. Proin eu metus vitae enim vehicula vehicula eu id orci. Pellentesque egestas pulvinar feugiat.</p>
        <br /><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eu lobortis elit. Praesent ut urna hendrerit, lobortis tortor at, vehicula massa. Proin eu metus vitae enim vehicula vehicula eu id orci. Pellentesque egestas pulvinar feugiat.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eu lobortis elit. Praesent ut urna hendrerit, lobortis tortor at, vehicula massa. Proin eu metus vitae enim vehicula vehicula eu id orci. Pellentesque egestas pulvinar feugiat. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.lorem</p>`
      },
      blogs: [
        { imageUrl: 'public/images/pages/blog/article-2.jpg', title: 'Cómo Elegir la Joyería Perfecta para Cada Estilo Personal' },
        { imageUrl: 'public/images/pages/blog/article-3.jpg', title: 'Los Secretos de la Joyería Atemporal: Inversiones que Nunca Pasan de Moda' },
        { imageUrl: 'public/images/pages/blog/article-4.jpg', title: 'Tendencias en Joyería para 2024: Los Must-Have para Elevar Tu Estilo' },
      ],
      id: null,
    }
  },
  watch:{
      '$route.params.id':function(){
           this.id = this.$root._getURLID(this.$route.params.id);
          this.getBlogs();
      }
  },

  methods: {
    getBlogs: function(){
      axios.get(tools.url('/api/blogs/'+this.id)).then((response)=>{
          this.noticia = response.data.new;
          this.blogs = response.data.news;
      });
    }
  },

  mounted(){
     this.id = this.$root._getURLID(this.$route.params.id);
     // this.getBlogs();
  },
}
</script>
