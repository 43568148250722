<template lang="html">
  <div class="col-cart-data loggedin-cart">

    <div class="col-12 pt-3 product-list-table">
      <div class="row row-products align-items-center row-history" v-if="!pedidos.length">
        No existen movimientos en este momento.
      </div>

      <div class="row" v-for="(order, index) in pedidos" :key="index" v-bind:class=" index > 0 ? 'mt-2': ''">
          <div class="col">
              <div class="card card-2">
                  <div class="card-body">
                      <div class="media">
                          <div class="media-body my-auto text-right">
                              <div class="row my-auto flex-column flex-md-row">
                                  <div class="col-auto my-auto ">
                                    <small>Pedido ID:</small><br><h6 class="mb-0"><router-link :to="'/usuario/pedido/'+order.id">MX000{{ order.id }}</router-link></h6>
                                  </div>
                                  <div class="col my-auto "> <small>Fecha:<br> {{ order.date }} </small></div>
                                  <!-- <div class="col my-auto "> <small>Estatus: {{ order.status }}</small></div> -->
                                  <div class="col my-auto ">
                                      <h6 class="mb-0">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(order.total) }}</h6>
                                  </div>
                                  <div class="col my-auto ">

                                    <button class="btn btn-info" style="background-color: #e91f78;border-color: #e91f78;padding: 5px;font-size: 12px;" @click="addProblem(order.id,index)">Reportar problema</button>
                                  </div>
                              </div>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <sweet-modal ref="modalProblem">
        <b-form @submit.prevent="onSubmit($event.target)">

          <p style="text-align: center;">En ANLESA nos preocupamos por la calidad de los productos y servicios que comercializamos, y por la mejor experiencia de nuestros clientes, por favor describe el problema que tuviste (puedes agregar fotos).</p>
          <br>
          <h4 class="b-title"> MX000{{ form.orders_id }}</h4>
          <br>

          <b-form-group class="cus-f-group-1 col-md-12" label="Producto" v-if="pedidos.length > 0">
            <v-select v-model="form.products_id" :options="pedidos[form.index].products" label="name" index="id"/>
          </b-form-group>

          
          
          <b-form-group class="cus-f-group-1 col-md-12" label="Agrega foto(1) (Opcional)">
            <div class="fileinput fileinput-new" data-provides="fileinput">
              <div class="fileinput-preview fileinput-exists thumbnail"></div>
              <div>
                <span class="btn btn-white btn-file fileinput-con">
                  <input type="file" accept="image/*," name="image1" id="image1">
                </span>
              </div>
            </div>
          </b-form-group>
          <b-form-group class="cus-f-group-1 col-md-12" label="Agrega foto(2) (Opcional)">
            <div class="fileinput fileinput-new" data-provides="fileinput">
              <div class="fileinput-preview fileinput-exists thumbnail"></div>
              <div>
                <span class="btn btn-white btn-file fileinput-con">
                  <input type="file" accept="image/*," name="image2" id="image2">
                </span>
              </div>
            </div>
          </b-form-group>
          <b-form-group class="cus-f-group-1 col-md-12" label="Agrega foto(3) (Opcional)">
            <div class="fileinput fileinput-new" data-provides="fileinput">
              <div class="fileinput-preview fileinput-exists thumbnail"></div>
              <div>
                <span class="btn btn-white btn-file fileinput-con">
                  <input type="file" accept="image/*," name="image3" id="image3">
                </span>
              </div>
            </div>
          </b-form-group>

          <b-form-group class="cus-f-group-1 col-md-12" label="Comentarios">
            <b-form-textarea
              id="textarea"
              v-model="form.comments"
              placeholder=""
              rows="5"
              max-rows="5"
            ></b-form-textarea>
          </b-form-group>

          <div class="col-12 mt-3">
              <b-button type="submit" class="btn-s1 b-pink" id="btnsubmit">Registrar problema</b-button>

            </div>

        </b-form>
      </sweet-modal>

      <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block" ref="modalAlert">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        <div v-html="modal.msg"></div>
        
      </sweet-modal>

    </div>

  </div>
</template>

<script>
export default {
  data(){
    return{
      pedidos: [],
      id: null,
      estatus: '',
      form:{
        orders_id:null,
        comments:null,
        products_id:null,
        index:0
      },
      modal:{
          msg:'',
          icon:'',
          block:false,
        },
    }
  },

  methods: {
    getHistorial(){
      this.modal.icon = '';
      this.modal.msg = 'Cargando ...';
      this.$refs.modalAlert.open();

      axios.get(tools.url("/api/user/orders")).then((response)=>{
        this.pedidos = response.data;
        this.$refs.modalAlert.close();
      }).catch((error)=>{
          console.log(error);
          this.$refs.modalAlert.close();
      });
    },
    addProblem(order_id,indx){
      this.form.index = indx;
        this.form.orders_id = order_id;
        this.form.products_id = null;
        this.$refs.modalProblem.open();
    },

    onSubmit(form){

      
      this.modal.icon = '';
      this.modal.msg = 'Cargando ...';
      this.$refs.modalProblem.close();
      this.$refs.modalAlert.open();

      var data=tools.params(form,this.form);

      axios.post(tools.url("/api/orderProblem"),data).then((response)=>{
        
        this.modal.block = false;
        this.modal.icon = 'success';
        this.modal.msg = 'Muchas gracias.<br><br>Hemos reportado tu problema a nuestro equipo de atención al cliente.<br><br>Si necesitas más ayuda puedes comunicarte a ayuda@anlesa.com o al WhatsApp de +52 1 33 1708 5870';
        
      }).catch((error)=>{
        this.modal.block = false;
        this.modal.icon = 'error';
        this.modal.msg = 'Error al guardar la información';
      });
    }
  },

  mounted(){
    if(this.$root.logged){
        this.getHistorial();
    }
  }

}
</script>
