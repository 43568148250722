<template lang="html">
  <div id="results-page">

    <section class="breadcrumb-section">
      <div class="container oversized-container">
        <span>Búsqueda</span>
        <span class="line">/</span>
        <span>{{ $route.query.keywords }}</span>
      </div>
    </section>

    <section class="container oversized-container products-section">
      <div class="row mb-3">
        <div class="col-12 col-page-title">
          <h2 class="title-s2">Búsqueda: <small>{{ $route.query.keywords }}</small></h2>
        </div>
      </div>

      <div class="row">


        <!-- Results -->
        <div class="col-lg">
          <div class="row sample-products-wrapper xxl-5e">
            <div class="col-sm-6 col-lg-4 sample-product shad-prod" v-for="(product, index) in products" :key="index">
              <router-link class="outlined-box" :to="'/producto/'+$root._converToURL(product.name, product.id)">
                <div class="box-image">
                  <!-- <div class="out-of-stock" v-if="product.stock < 1">Agotado</div> -->

                  <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.img+')' }">
                    <img src="public/images/shared/product.png" >
                  </div>
                </div>

                <div class="box-shot-descr">
                  <h6 class="sku">{{ product.sku }}</h6>
                  <h6 class="name para-5">{{ product.name }}</h6>
                  <h6 class="brand">{{ product.category }}</h6>

                  <h6 class="price" v-bind:class="{ 'discount' : product.activate_discount }" style="min-height: 51px;">
                      <!-- PRECIO TACHADO SIN DESCUENTO-->
                      <span class="cross" v-if="product.type_disc == 'descuento_envio' || product.type_disc == 'descuento'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }} </span>
                      <!-- PRECIO NORMAL-->
                      <span v-else-if="product.type_disc == null || product.type_disc == 'envio'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }} </span>
                      <!-- PRECIO FINAL CON DESCUENTO-->
                      <span v-if="product.type_disc == 'descuento_envio' || product.type_disc == 'descuento'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format( product.price_discount) }}</span>
                      <!-- ENVIO GRATIS-->
                      <span class="free-shipping" v-if="product.type_disc == 'descuento_envio' || product.type_disc == 'envio'"><i class="fas fa-shipping-fast"></i> Envío gratis</span>
                  </h6>
                  <h6 v-if="product.stock > 0" class="stock">{{ product.stock }} en stock</h6>
                  <h6 v-else style="color: gray;">Sin stock</h6>
                </div>
                <p class="p-button">
                  <a class="btn-more">Ver más</a>
                </p>
              </router-link>
            </div>

            <h5 v-if="!products.length" style="text-align:center;">Aun no tenemos productos en esta categoría. ¿ Tienes un emprendimiento relacionado a esto?  <router-link to="/asociarse"> ¡Regístra tu negocio ! </router-link></h5>
          </div>

          <div class="row" v-if="pagination.total_products > 6">
                  <div class="col-12 text-center">
                    <div class="d-inline-block mx-0 col-pagination-sample-1">
                      <b-pagination-nav :link-gen="linkGen" :number-of-pages="pagination.total" use-router></b-pagination-nav>
                    </div>
                  </div>
          </div>
        </div>
        <!--  -->
        <sweet-modal ref="modalLoading" hide-close-button blocking width="20%">
          <modalLoadingComponent></modalLoadingComponent>
              <p style="font-weight: 600; color: #291063; font-size: 1.1rem;">Cargando, espere ...</p>
        </sweet-modal>

      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      isLoading: true,
      showFilters: false,

      products: [
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
      ],

      filters: {
        keywords: '',
      },
      pagination:{
        currentpage:1,
        total:1,
        number: 10,
        total_products:1,
      },
    }
  },
  watch:{
      '$route.query.keywords':function(){
          this.filters.keywords = this.$route.query.keywords;
          //this.pagination.currentpage = 1;
          this.getSearch();
      },
      '$route.query.page':function(val){

        if (val) {
            this.pagination.currentpage = val;

        }
        else{
            this.pagination.currentpage = 1;
        }
          this.getSearch();
      },
  },

  methods: {
    linkGen(pageNum) {

      return pageNum === 1 ? `?keywords=${this.filters.keywords}` : `?keywords=${this.filters.keywords}&page=${pageNum}`
    },
    getSearch(){
      this.$refs.modalLoading.open();
      this.isLoading = true;

      axios.post(tools.url('/api/search'+"?page=" + this.pagination.currentpage),this.filters).then((response)=>{
        this.products = response.data.data;
        this.pagination.total = response.data.last_page;
        this.pagination.total_products = response.data.total;


        this.isLoading = false;
        this.$refs.modalLoading.close();
      }).catch((error)=>{
        console.log(error);
        this.$refs.modalLoading.close();
      });
    },
  },

  mounted(){
    if(this.$route.query.keywords){
      this.filters.keywords = this.$route.query.keywords;
      this.getSearch();
    }
  },

}
</script>
