<template lang="html">
  <div id="results-page">

    <section class="breadcrumb-section">
      <div class="container oversized-container">
        <span>Promociones</span>
        <span class="line">/</span>
        <span>{{ $route.query.keywords }}</span>
      </div>
    </section>

    <section class="container oversized-container products-section">
      <div class="row mb-3">
        <div class="col-12 col-page-title">
          <h2 class="title-s2">Promociones</h2>
        </div>
      </div>

      <div class="row">
        <!-- Mobile filters -->
        <div class="col-12 col-filters-mobile">
          <div class="bg-filter-mv" v-if="showFilters == true" @click="showFilters = false"></div>
          <a class="btn-filters" @click="showFilters = !showFilters">
            <i class="fas fa-filter"></i><span>Ver filtros</span>
          </a>
        </div>
        <!--  -->

        <!-- Col filters -->
        <div class="col-lg col-filters" v-bind:class="{ 'show-filters': showFilters == true }">
          <h4 class="_title">
            <span>Filtros de Búsqueda</span>
            <a class="btn-hide" @click="showFilters = false"><i class="fas fa-chevron-left"></i></a>
          </h4>

          <b-form class="filters-container">
            <!-- Marca -->
            <div class="box-filters">
              <a class="btn-action"><i class="sy">-</i> Marca</a>

              <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  v-model="filter_brand"
                  :aria-describedby="ariaDescribedby"
                  name="filter-1">
                  <b-form-radio :value="i.id" v-for="(i, fbrInx) in brandsOptions" :key="'fbrInx-'+fbrInx">{{ i.name }}</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div>
            <!--  -->

          </b-form>
        </div>
        <!--  -->

        <!-- Results -->
        <div class="col-lg">
          <div class="row sample-products-wrapper xxl-4e">
            <div class="col-sm-6 col-lg-4 sample-product shad-prod" v-for="(product, index) in products" :key="index">
              <router-link class="outlined-box" :to="'/producto/'+$root._converToURL(product.name, product.id)">
                <div class="box-image">
                  <div class="out-of-stock" v-if="product.stock < 1">Agotado</div>

                  <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.img+')' }">
                    <img src="public/images/shared/product.png" >
                  </div>
                </div>

                <div class="box-shot-descr">
                  <h6 class="sku">{{ product.sku }}</h6>
                  <h6 class="name">{{ product.name }}</h6>
                  <h6 class="brand">{{ product.user }}</h6>
                  <h6 class="price" v-bind:class="{ 'discount' : product.offer_price }">
                    <span class="cross">${{ product.price }}</span>
                    <span v-if="product.activate_discount == 1 && product.free_shipping == 0">${{ product.price_discount }}</span>
                        <span v-if="product.activate_discount == 1 && product.free_shipping == 1">Envío gratis</span>
                    
                  </h6>
                </div>
                <p class="p-button">
                  <a class="btn-more">Ver más</a>
                </p>
              </router-link>
            </div>

            <h5 v-if="!products.length">No se encontraron resultados</h5>
          </div>
        </div>
        <!--  -->

      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      isLoading: true,
      showFilters: false,

      products: [
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Guantes de equitación profesional', sku: '4X2S3SH', stock: 1, price: '300', offer_price: '150.00' },
        { id: 1, img: 'public/images/pages/products/product-2.jpg', name: 'Guantes de equitación profesional', sku: '4X2S3SH', stock: 1, price: '300', offer_price: '150.00' },
        { id: 1, img: 'public/images/pages/products/product-3.jpg', name: 'Guantes de equitación profesional', sku: '4X2S3SH', stock: 1, price: '300', offer_price: '150.00' },
        { id: 1, img: 'public/images/pages/products/product-4.jpg', name: 'Guantes de equitación profesional', sku: '4X2S3SH', stock: 1, price: '300', offer_price: '150.00' },
        { id: 1, img: 'public/images/pages/products/product-5.jpg', name: 'Guantes de equitación profesional', sku: '4X2S3SH', stock: 1, price: '300', offer_price: '150.00' },
        { id: 1, img: 'public/images/pages/products/product-5.jpg', name: 'Guantes de equitación profesional', sku: '4X2S3SH', stock: 1, price: '300', offer_price: '150.00' },
        { id: 1, img: 'public/images/pages/products/product-4.jpg', name: 'Guantes de equitación profesional', sku: '4X2S3SH', stock: 1, price: '300', offer_price: '150.00' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Guantes de equitación profesional', sku: '4X2S3SH', stock: 1, price: '300', offer_price: '150.00' },
        { id: 1, img: 'public/images/pages/products/product-3.jpg', name: 'Guantes de equitación profesional', sku: '4X2S3SH', stock: 1, price: '300', offer_price: '150.00' },
        { id: 1, img: 'public/images/pages/products/product-2.jpg', name: 'Guantes de equitación profesional', sku: '4X2S3SH', stock: 1, price: '300', offer_price: '150.00' },
      ],
      productsAll:[],

      filter_brand:'null',
      brandsOptions:[
        { id: null, name: 'Todas las marcas' },
        { id: 1, name: 'Marca x 1' },
        { id: 2, name: 'Marca x 2' },
        { id: 3, name: 'Marca x 3' },
        { id: 4, name: 'Marca x 4' },
      ],

      filters: {
        keywords: '',
      },
    }
  },

  methods: {
    getSearch(){
      this.isLoading = true;

      axios.post(tools.url('/api/search'),this.filters).then((response)=>{
        this.products = response.data;
        this.productsAll = response.data;

        // this.brandsOptions = response.data.brands;
        // var aux = {
        //   id:'null',
        //   name:'Filtrar por marca'
        // }
        // this.brandsOptions.unshift(aux);

        this.isLoading = false;
      }).catch((error)=>{
        console.log(error);
      });
    },
  },

  mounted(){
    if(this.$route.query.keywords){
      this.filters.keywords = this.$route.query.keywords;
      this.getSearch();
    }
  },

  watch: {
    'filter_brand': function(val){
        if (val != 'null') {
            var newproducts = [];
            for (var i = 0; i < this.productsAll.length; i++) {
                if(this.productsAll[i].brand_id == val){
                    newproducts.push(this.productsAll[i]);
                }
            }
            this.products = newproducts;
        }
        else{
            this.products = this.productsAll;
        }
    }
  },
}
</script>
