<template lang="html">
  <footer id="footer">
    <section class="newsletter-section">
      <h2>Newsletter</h2>

      <b-form @submit.prevent="onSubmit">
        <b-form-group>
          <b-form-input
            v-model="form.email"
            type="email"
            required
            placeholder="Escribe tu correo"
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" class="btn-send">Suscribirte</b-button>
      </b-form>
    </section>

  	<section class="footer-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-9">
            <div class="row">
              <div class="col-lg-5 col-footer">
                <h5>Mapa del sitio</h5>

                <p class="f-w-300"><router-link class="link-footer" to="/">Inicio</router-link></p>
                <p class="mt-lg-1"><router-link class="link-footer" to="/login">Mi cuenta</router-link></p>
                <!-- <p class="mt-lg-1"><router-link class="link-footer" to="/ofertas">Ofertas</router-link></p> -->
                <!-- <p class="mt-lg-1"><router-link class="link-footer" to="/compra-en">Compra en <strong>M</strong></router-link></p> -->
                <p class="mt-lg-1"><router-link class="link-footer" to="/nosotros">Sobre nosotros</router-link></p>

                <p class="mt-lg-1 f-w-300"><router-link class="link-footer" to="/politica-de-envio-devoluciones">Envío y devoluciones</router-link></p>

                <!-- <p class="mt-lg-1 f-w-300"><router-link class="link-footer" to="/quejas-sugerencias">Quejas y sugerencias</router-link></p> -->

                <p class="mt-lg-1 f-w-300"><router-link class="link-footer" to="/asociarse">Vende con nosotros</router-link></p>
              </div>

              <div class="col-lg-7 col-footer">
                <h5>Información</h5>

                <p class="mt-lg-1"><router-link class="link-footer" to="/terminos-y-condiciones">Términos y condiciones</router-link></p>
                <p class="mt-lg-1"><router-link class="link-footer" to="/aviso-de-privacidad">Aviso de privacidad</router-link></p>
                <p class="mt-lg-1"><router-link class="link-footer" to="/preguntas-frecuentes-clientes">Preguntas frecuentes de los Clientes</router-link></p>
                <p class="mt-lg-1"><router-link class="link-footer" to="/preguntas-frecuentes-afiliados">Preguntas frecuentes de los Afiliados</router-link></p>
                <!-- <p class="mt-lg-1"><router-link class="link-footer" to="/declaracion-de-privacidad-confidencialidad">Declaración de privacidad y confidencialidad de la información de ANLESA</router-link></p> -->
                <!-- <p class="mt-lg-1"><router-link class="link-footer" to="/politica-de-envio-devoluciones">Política de envío y devoluciones de Anlesa</router-link></p> -->
                <!-- <p class="mt-lg-1"><router-link class="link-footer" to="/convenio-anlesa">Convenio para creadores de Anlesa</router-link></p> -->
                <!-- <p class="mt-lg-1"><router-link class="link-footer" to="/terminos-y-condiciones-de-uso">Términos y condiciones de uso de Anlesa</router-link></p> -->

                <p class="mt-2 copy-text">
                  Derecho Reservados Anlesa 2025
                </p>
              </div>
            </div>
          </div>


          <div class="col-lg-3 col-footer col-copyright align-self-center">
            <p class="mb-3">
              <!-- <router-link class="t-150 btn-network" to="/contacto">
                <span><i class="fal fa-envelope"></i></span>
              </router-link> -->
              <a class="t-150 btn-network" target="_blank" href="https://www.instagram.com/anlesa">
                <span><i class="fab fa-instagram"></i></span>
              </a>
              <a class="t-150 btn-network" target="_blank" href="https://web.facebook.com/anlesa">
                <span><i class="fab fa-facebook-f"></i></span>
              </a>
            </p>

            <p class="p-sm">
              Av Adolfo López Mateos Nte 95-Interior 26,<br />
              Italia Providencia, 44648<br />
              Guadalajara, Jal.
            </p>
            <p class="mt-1 p-sm">
              Email. contacto@anlesa.com
            </p>
            <p class="mt-2 p-sm">
              Tel. 33 3210 1234
            </p>
            <p class="mt-lg-1"><router-link class="link-footer" to="/contacto">Contacto</router-link></p>
          </div>
  			</div>
      </div>

      <div class="box-apps">
        <h5 class="title">Descarga nuestra App</h5>

        <a class="btn-app" target="_blank" href="">
          <img src="public/images/shared/app-store.svg" alt="App Store">
        </a>

        <a class="btn-app" target="_blank" href="">
          <img src="public/images/shared/google-play.svg" alt="Google play">
        </a>
      </div>
    </section>
  </footer>
</template>

<script>
export default {
  data(){
    return{
      form: {
        email: null
      },
    }
  },

  methods:{
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      });
    },

    onSubmit(evt) {
      evt.preventDefault();

        axios.post(tools.url("/api/newsletter"), this.form).then((response)=>{
          alert('Correo registrado correctamente');
          /*this.makeToast('default', response.data.response, 'Correo registrado');
          this.form = {
            name: null,
            email: null,
            msg: null,
            check: null
          };*/
        }).catch( (error)=> {
          // console.log(error.response);
          let errors = error.response.data;
          // console.log(errors);
          this.makeToast('danger', error.response.data.name, 'Error');
          this.makeToast('danger', error.response.data.email, 'Error');
          this.makeToast('danger', error.response.data.phone, 'Error');
          this.makeToast('danger', error.response.data.msg, 'Error');
        });

    },
  }
}
</script>
