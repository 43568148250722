<template lang="html">
  <header id="header">

    <div class="header-content">
      <!-- <div class="header-temp">
        <h5>Estamos en fase Beta.</h5>
      </div> -->

      <div class="header-top">
        <b-navbar toggleable="lg" type="light" variant="light">
          <div class="container oversized-container">
            <b-navbar-brand to="/">
              <img src="public/images/logo.svg">
            </b-navbar-brand>

            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav>
                <!-- <b-nav-item to="">Link</b-nav-item> -->
              </b-navbar-nav>

              <!-- Right aligned nav items -->
              <b-navbar-nav class="ml-auto">
               <!--  <b-nav-item-dropdown class="mr-2 dropdown-lang" right>
                  <template #button-content>
                    <span>Español (MX)</span>
                  </template>
                  <b-dropdown-item>Español (MX)</b-dropdown-item>
                  <b-dropdown-item>English (US)</b-dropdown-item>
                </b-nav-item-dropdown> -->

                <b-nav-item class="mr-3 special-item green-s-item" to="/asociarse">Registra tus productos y véndelos</b-nav-item>

                <!-- <li class="nav-item nav-icon">
                  <a class="nav-link" @click="$refs['modal-search'].show()"><i class="far fa-search"></i></a>
                </li> -->

              <b-nav-item class="nav-icon" to="/cart">
                <i class="far fa-shopping-cart"></i><span class="num">{{ $root.cartCount }}</span>
              </b-nav-item>
              <b-nav-item v-if="$root.logged" class="nav-icon" to="/usuario"><i class="far fa-user"></i> Mi cuenta</b-nav-item>

                <b-nav-item-dropdown class="nav-icon" right v-if="!$root.logged">
                  <template #button-content>
                    <span><i class="far fa-user"></i></span>
                  </template>
                  <li role="presentation">
                    <a class="dropdown-item" target="_blank" href="https://anlesa.com/login">Iniciar sesión como PYME & Emprendedor</a>
                  </li>
                  <!-- <b-dropdown-item to="/registrarse">Registrarse</b-dropdown-item> -->
                  <b-dropdown-item to="/asociarse">Registra tus productos y vendelos</b-dropdown-item>
                  <b-dropdown-item to="/login">Iniciar sesión como cliente</b-dropdown-item>
                  <b-dropdown-item to="/registrarse">Crea una cuenta para comprar</b-dropdown-item>
                </b-nav-item-dropdown>

                <b-nav-item class="ml-2 special-item" to="/contacto">Contáctanos</b-nav-item>
              </b-navbar-nav>
            </b-collapse>
          </div>
        </b-navbar>
      </div>

      <div class="header-bottom">
        <b-navbar toggleable="lg" type="dark" variant="black">
          <div class="container oversized-container">
            <b-navbar-brand class="d-lg-none" to="/">
              <img src="public/images/logo-white.svg">
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"><i class="far fa-bars"></i></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav>
                <li class="nav-item simple-item">
                  <a class="nav-link" @click="isPActive = !isPActive">Categorías <i class="fas fa-sort-down icon-sort-down"></i></a>
                </li>
               <!--  <b-nav-item class="simple-item" to="/ofertas">Ofertas</b-nav-item> -->
                <b-nav-item class="simple-item" to="/nosotros">Sobre nosotros</b-nav-item>
                <b-nav-item class="simple-item" to="/blog">Blog</b-nav-item>
                <b-nav-item class="simple-item" to="/asociarse">Asóciate</b-nav-item>
                <b-nav-item class="simple-item d-lg-none" to="/contacto">Contáctanos</b-nav-item>

                <li class="nav-item simple-item d-lg-none">
                  <router-link class="nav-link" target="_self" to="/asociarse"><span class="f-w-600">Registra tus productos y vendelos</span></router-link>
                </li>

                <b-nav-item class="simple-item cart-item-mb d-lg-none" to="/cart">
                  <i class="far fa-shopping-cart"></i> Carrito
                  <span class="num">{{ $root.cartCount }}</span>
                </b-nav-item>
                <b-nav-item class="simple-item cart-item-mb d-lg-none" to="/usuario" v-if="$root.logged">
                  <i class="far fa-user"></i> Mi cuenta
                </b-nav-item>

                <b-nav-item-dropdown class="simple-item d-lg-none" right v-if="!$root.logged">
                  <template #button-content>
                    <span><i class="far fa-user"></i> Mi cuenta</span>
                  </template>
                  <li role="presentation">
                    <a class="dropdown-item" target="_blank" href="https://anlesa.com/login">Iniciar sesión como PYME & Emprendedor</a>
                  </li>
                  <!-- <b-dropdown-item to="/registrarse">Registrarse</b-dropdown-item> -->
                  <b-dropdown-item to="/asociarse">Registra tus productos y vendelos</b-dropdown-item>
                  <b-dropdown-item to="/login">Iniciar sesión como cliente</b-dropdown-item>
                  <b-dropdown-item to="/registrarse">Crea una cuenta para comprar</b-dropdown-item>
                </b-nav-item-dropdown>
              </b-navbar-nav>

              <!-- Right aligned nav items -->
              <b-navbar-nav class="ml-auto">
                <b-nav-form class="search-form" @submit.prevent="searchForm">
                  <b-form-input size="sm" placeholder='Buscar en todas las categorías...' v-model="search.keywords"></b-form-input>
                  <b-button class="btn-search" type="submit"></b-button>
                  <b-button class="btn-empty" type="button" @click="resetSearchForm"></b-button>

                  <div class="box-autocomplete" v-bind:class="{ active: isAutocompActive }">
                    <div class="box">
                      <div class="box-results">

                        <div class="d-block text-center">
                          <loading3 v-if="isAuComLoading"></loading3>
                        </div>

                        <router-link class="flex-center-xy row-product"
                          :to="'/producto/'+$root._converToURL(p.name, p.id)"
                          v-for="(p, rInx) in autocompProds"
                          v-if="autocompProds.length > 0"
                          :key="'rInx-'+rInx">
                          <div class="col img" v-bind:style="{ backgroundImage: 'url('+p.img+')' }"></div>
                          <div class="col txt">
                            <h6 class="p-name">{{ p.name }}</h6>
                            <h6 class="cat-subcat">{{ p.category }} / {{ p.subcategory }}</h6>
                          </div>
                        </router-link>

                        <router-link class="flex-center-xy trans-150 row-product all" v-if="autocompProds.length > 0" :to="{ path: '/busqueda', query: {keywords:this.search.keywords} }">
                          <div class="col txt text-center">Mostrar todos</div>
                        </router-link>

                        <a class="flex-center-xy row-product no-shad" v-if="autocompProds.length <= 0 && !isAuComLoading">
                          <div class="col txt text-center">No se encontraron resultados</div>
                        </a>
                      </div>
                    </div>
                  </div>
                </b-nav-form>

                <li class="nav-item networks-item">
                  <div class="nav-link">
                    <a class="btn-network" target="_blank" href="https://www.instagram.com/anlesa"><i class="fab fa-instagram"></i></a>
                    <a class="btn-network" target="_blank" href="https://web.facebook.com/anlesa"><i class="fab fa-facebook-f"></i></a>
                  </div>
                </li>
              </b-navbar-nav>
            </b-collapse>
          </div>
        </b-navbar>
      </div>
    </div>

    <!-- Products menu -->
    <div class="t-250 products-menu" v-bind:class="{ active: isPActive }">
      <div class="menu-bg" @click="isPActive = false"></div>

      <div class="tablist" role="tablist">
        <div class="t-250 box-title" @click="isPActive = false">
          <h4>PRODUCTOS</h4>
          <br>
          <h5>Categorias</h5>
          <i class="fas fa-chevron-left icon"></i>
        </div>

        <b-card no-body class="mb-1" v-for="(category, pcatIndex) in categories" :key="pcatIndex">
          <b-card-header header-tag="header" role="tab">
            <div v-if="category.subcategories.length" class="btn-toggle">
              <router-link :to="'/categoria/'+$root._converToURL(category.name, category.id)">{{ category.name }}</router-link>
              <span class="fake-toggle" v-b-toggle="'accordion-'+pcatIndex"></span>
            </div>
            <router-link v-else class="btn-toggle-fake" :to="'/categoria/'+$root._converToURL(category.name, category.id)">{{ category.name }}</router-link>
          </b-card-header>

          <b-collapse v-if="category.subcategories.length" :id="'accordion-'+pcatIndex"  accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <ul class="ul-sub-options">
                <li v-for="subcategory in category.subcategories">
                  <router-link class="t-250" :to="'/subcategoria/'+$root._converToURL(subcategory.name, subcategory.id)">{{ subcategory.name }}</router-link>
                </li>
              </ul>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
    <!--  -->

  </header>
</template>

<script>
import loading3 from './loading3-component.vue';
export default {
  components: {
    loading3
  },

  data(){
    return{
      isPActive: false,
      categories:[],
      categories:[
        { id: 1, name: 'Tipo de Joyería', subcategories: [
          { id: 2, name: 'Anillos' },
          { id: 3, name: 'Collares' },
          { id: 4, name: 'Aretes' },
          { id: 5, name: 'Pulseras' },
          ]
        },
        { id: 10, name: 'Materiales', subcategories: [
          { id: 5, name: 'Oro' },
          { id: 8, name: 'Plata' },
          { id: 9, name: 'Piedras preciosas' },
          ]
        },
        { id: 182, name: 'Estilos de Joyería', subcategories: [
          { id: 12, name: 'Joyería clásica' },
          { id: 13, name: 'Joyería contemporánea' },
          { id: 14, name: 'Joyería bohemia' },
          ]
        },
        { id: 182, name: 'Ocasiones', subcategories: [] },
      ],

      isAutocompActive: false,
      isAuComLoading: true,
      autocompProds: [],

      search: {
        keywords: '',
      },
    }
  },

  methods: {
    searchForm(evt){
      this.$router.push({path: '/busqueda', query: {keywords:this.search.keywords}});
    },

    resetSearchForm(){
      this.search.keywords = null;
      this.isAutocompActive = false;
    },

    getCategories(){

      axios.get(tools.url("/api/categoriesFront")).then((response)=>{
          this.categories = response.data;
          }).catch((error)=>{

        });
    },
  },

  watch: {
    $route (to, from){
      // Ocultar menu cada vez que cambie la ruta
      this.isPActive = false;

      // Ocultar el autocomplete
      this.isAutocompActive = false;
      this.autocompProds = [];
      this.search.keywords = null;
    },
    'search.keywords' (val, oldVal){
      this.autocompProds = [];

      if(val.length >= 3){ // Buscar solo si tiene al menos 3 letras
        this.isAutocompActive = true;
        this.isAuComLoading = true;

        axios.post( tools.url('/api/autocomplete-search/'+this.search.keywords) ).then((response)=>{
          this.autocompProds = response.data;
          this.isAuComLoading = false;
        }).catch((error)=>{
          console.log(error);
        });
      }else{
        this.isAutocompActive = false;
        this.isAuComLoading = false;
        this.autocompProds = [];
      }
    }


  },
  mounted(){
    this.getCategories();
  }
}
</script>
