<template lang="html">
  <div id="contact-page">

    <section class="container main-section">
      <div class="row align-items-center">
        <div class="col-12 col-title">
          <h1 class="title-s1">Contacto</h1>
        </div>

        <div class="col-lg-6 col-info">
          <h5 class="title">¿Tienes preguntas?<br />Envíanos un mensaje o comunicate con nosotros.</h5>

          <h5 class="mt-3 subtitle">Dirección</h5>
          <p>
            Av Adolfo López Mateos Nte 95-Interior 26,<br />
            Italia Providencia, 44648<br />
            Guadalajara, Jal.
          </p>

          <h5 class="mt-3 subtitle">Teléfono</h5>
          <p>
            Tel. 33 3210 1234
          </p>

          <h5 class="mt-3 subtitle">Correo</h5>
          <p>
            contacto@anlesa.com
          </p>

          <h5 class="mt-3 subtitle">Síguenos</h5>
          <p>
            <a class="btn-network mr-1" href="https://www.instagram.com/anlesa"><i class="fab fa-instagram"></i></a>
           <!--  <a class="btn-network ml-1" href="#"><i class="fab fa-twitter"></i></a> -->
            <a class="btn-network ml-1" href="https://web.facebook.com/anlesa"><i class="fab fa-facebook-f"></i></a>
            <!-- <a class="btn-network ml-1" href="#"><i class="fab fa-linkedin-in"></i></a> -->
          </p>
        </div>

        <div class="col-lg-6 col-form">
          <b-form @submit="onSubmit">
            <div class="row">
              <b-form-group class="cus-f-group-1 col-12" label="Nombre completo">
                <b-form-input type="text" v-model="form.name" required placeholder=""></b-form-input>
              </b-form-group>

              <b-form-group class="cus-f-group-1 col-12" label="Correo electrónico">
                <b-form-input type="email" v-model="form.email" required placeholder=""></b-form-input>
              </b-form-group>

              <b-form-group class="cus-f-group-1 col-12" label="Teléfono">
                <b-form-input type="text" v-model="form.phone" minlength="10" maxlength="10" required placeholder=""></b-form-input>
              </b-form-group>

              <b-form-group class="cus-f-group-1 col-12" label="Mensaje">
                <b-form-textarea
                  v-model="form.msg"
                  placeholder=""
                  rows="5"
                  max-rows="5"
                ></b-form-textarea>
              </b-form-group>

              <div class="col-12">
                <b-button type="submit" class="btn-s1 b-pink">ENVIAR</b-button>
              </div>
            </div>
          </b-form>
        </div>
        <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
        <div v-html="modal.msg"></div>
        <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
            <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close()">Aceptar</b-button>

        </div>
    </sweet-modal>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        name: null,
        email:null,
        phone:null,
        msg:null
      },
      modal:{
        msg:'',
        icon:'',
        block:false
      },
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      axios.post(tools.url('/api/contact'),this.form).then((response)=>{
          this.modal.icon = 'success';
          this.modal.msg = 'Información guardada existosamente';
          this.$refs.modal.open();
          this.form = {};

        }).catch((error)=>{
          console.log(error);
          this.modal.icon = 'error';
          this.modal.msg = 'Error al guardar la información';
          this.$refs.modal.open();
        })
    }
  },
}
</script>
