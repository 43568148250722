<template lang="html">
  <div id="blog-page">
    <section class="container oversized-container articles-section">
      <div class="row">
        <div class="col-12 mb-3 col-page-title">
          <h2 class="title-s2">Comunicados</h2>
        </div>

        <div class="col-12 col-md-6 col-lg-4 col-xl-3 col-article article-sample-1" v-for="(article, ahInx) in blogs" :key="'ahInx-'+ahInx">
          <router-link class="t-250 placed-backg article-link" v-bind:style="{ backgroundImage: 'url('+article.imageUrl+')' }" :to="'/blog/'+article.id">
            <img class="empty" src="public/images/shared/empty.png">

            <div class="inside">
              <div class="t-250 show">Ver más</div>
              <div class="shadow"></div>
              <div class="box-txt">
                <h5>{{article.title}}</h5>


                <!-- <div class="descr">
                  <p>
                    Descripción breve del comunicado para pactar el interés del usuario.
                  </p>
                </div> -->
              </div>
            </div>
          </router-link>
        </div>

      </div>
    </section>
  </div>
</template>

<script>
export default {
  data(){
    return{
      blogs: [
        { id: 1, imageUrl: 'public/images/pages/blog/article-1.jpg', title: 'Joyería Sostenible: Lo Mejor de la Moda Ética para Este Año' },
        { id: 2, imageUrl: 'public/images/pages/blog/article-2.jpg', title: 'Cómo Elegir la Joyería Perfecta para Cada Estilo Personal' },
        { id: 3, imageUrl: 'public/images/pages/blog/article-3.jpg', title: 'Los Secretos de la Joyería Atemporal: Inversiones que Nunca Pasan de Moda' },
        { id: 4, imageUrl: 'public/images/pages/blog/article-4.jpg', title: 'Tendencias en Joyería para 2024: Los Must-Have para Elevar Tu Estilo' },

        { id: 4, imageUrl: 'public/images/pages/blog/article-4.jpg', title: 'Tendencias en Joyería para 2024: Los Must-Have para Elevar Tu Estilo' },
        { id: 3, imageUrl: 'public/images/pages/blog/article-3.jpg', title: 'Los Secretos de la Joyería Atemporal: Inversiones que Nunca Pasan de Moda' },
        { id: 2, imageUrl: 'public/images/pages/blog/article-2.jpg', title: 'Cómo Elegir la Joyería Perfecta para Cada Estilo Personal' },
        { id: 1, imageUrl: 'public/images/pages/blog/article-1.jpg', title: 'Joyería Sostenible: Lo Mejor de la Moda Ética para Este Año' },
      ],
    }
  },
  methods:{

    getBlogs(){

      axios.get(tools.url("/api/blogs")).then((response)=>{
          this.blogs = response.data;
          }).catch((error)=>{

        });
    },

  },

  mounted() {
      this.getBlogs();
  }
}
</script>
