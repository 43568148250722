<template lang="html">
  <div id="results-page">

    <section class="breadcrumb-section">
      <div class="container oversized-container">
        <span>Categorías</span>
        <span class="line">/</span>
        <span>{{ category.name }}</span>
       <!--  <span class="line">/</span>
        <span v-if="subcategory">{{ subcategory.name }}</span> -->
      </div>
    </section>

    <section class="container oversized-container products-section">
      <div class="row mb-3">
        <div class="col-12 col-page-title">
          <h2 class="title-s2">{{ category.name }}</h2>
        </div>
      </div>

      <div class="row">
        <!-- Mobile filters -->
        <div class="col-12 col-filters-mobile">
          <div class="bg-filter-mv" v-if="showFilters == true" @click="showFilters = false"></div>
          <a class="btn-filters" @click="showFilters = !showFilters">
            <i class="fas fa-filter"></i><span>Ver filtros</span>
          </a>
        </div>
        <!--  -->

        <!-- Col filters -->
        <div class="col-lg col-filters" v-bind:class="{ 'show-filters': showFilters == true }">
          <h4 class="_title">
            <span style="color:black;"><b>Filtrar</b></span>
            <a class="btn-hide" @click="showFilters = false"><i class="fas fa-chevron-left"></i></a>
          </h4>

          <b-form class="filters-container">
            <div class="box-filters">
              <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  id="checkbox-group-2"
                  v-model="selected"
                  :aria-describedby="ariaDescribedby"
                  name="fil-2">
                  <b-form-checkbox
                    :value="subc.id"
                    v-for="(subc, subInx) in subcategories"
                    :key="'subInx-'+subInx">
                    {{ subc.name }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>

              <!-- <br><span style="color:black;" v-if="id != 34"><b>Explora ANLESA</b><br><span style="font-size: 13px;">Filtro por procedencia del creador</span></span>
              <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }" v-if="id != 34">
                <b-form-checkbox-group
                  id="checkbox-group-2"
                  v-model="selected"
                  :aria-describedby="ariaDescribedby"
                  name="fil-2">
                  <b-form-checkbox
                    :value="subc.id"
                    v-for="(subc, subInx) in subcategoriesmexico"
                    :key="'subInx-'+subInx">
                    {{ subc.name }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group> -->
            </div>
          </b-form>
        </div>
        <!--  -->

        <!-- Results -->
        <div class="col-lg">
          <div class="row mb-4" v-if="showBanners">
            <div class="col-12 mb-1 mb-lg-3 col-banner" v-if="category.imageBanner1Url">
              <a :href="category.banner1_url">
                <!-- * Para escritorio * -->
                <img class="placed-backg" src="public/images/pages/products/banner-t1.png" v-bind:style="{ backgroundImage: 'url('+category.imageBanner1Url+')' }">

                <!-- * Para movil * -->
                <!-- <img class="placed-backg" src="public/images/pages/products/banner-t2.png" v-bind:style="{ backgroundImage: 'url(public/images/pages/products/424-190.png)' }"> -->
              </a>
            </div>

            <div class="col-lg mb-1 col-banner" v-if="category.imageBanner2Url">
              <a :href="category.banner2_url">
                <img class="placed-backg" src="public/images/pages/products/banner-t2.png" v-bind:style="{ backgroundImage: 'url('+category.imageBanner2Url+')' }">
              </a>
            </div>

            <div class="col-lg mb-1 col-banner" v-if="category.imageBanner3Url">
              <a :href="category.banner3_url">
                <!-- category.imageBanner3Url -->
                <img class="placed-backg" src="public/images/pages/products/banner-t2.png" v-bind:style="{ backgroundImage: 'url('+category.imageBanner3Url+')' }">
              </a>
            </div>

            <div class="col-lg mb-1 col-banner" v-if="category.imageBanner4Url">
              <a :href="category.banner4_url">
                <!-- category.imageBanner4Url -->
                <img class="placed-backg" src="public/images/pages/products/banner-t2.png" v-bind:style="{ backgroundImage: 'url('+category.imageBanner4Url+')' }">
              </a>
            </div>
          </div>

          <div class="row sample-products-wrapper xxl-4e">
            <div class="col-md-6 col-lg-6 sample-product shad-prod" v-for="(product, index) in products" :key="index">
              <router-link class="outlined-box" :to="'/producto/'+$root._converToURL(product.name, product.id)">
                <div class="box-image">
                  <!-- <div class="out-of-stock" v-if="product.stock < 1">Agotado</div> -->

                  <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.img+')' }">
                    <img src="public/images/shared/product.png" >
                  </div>
                </div>

                <div class="box-shot-descr">
                  <h6 class="sku">{{ product.sku }}</h6>
                  <h6 class="name para-5">{{ product.name }}</h6>
                  <h6 class="brand">{{ product.category }}</h6>

                  <h6 class="price" v-bind:class="{ 'discount' : product.activate_discount }" style="min-height: 51px;">
                      <!-- PRECIO TACHADO SIN DESCUENTO-->
                      <span class="cross" v-if="product.type_disc == 'descuento_envio' || product.type_disc == 'descuento'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }} </span>
                      <!-- PRECIO NORMAL-->
                      <span v-else-if="product.type_disc == null || product.type_disc == 'envio'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }} </span>
                      <!-- PRECIO FINAL CON DESCUENTO-->
                      <span v-if="product.type_disc == 'descuento_envio' || product.type_disc == 'descuento'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format( product.price_discount) }}</span>
                      <!-- ENVIO GRATIS-->
                      <span class="free-shipping" v-if="product.type_disc == 'descuento_envio' || product.type_disc == 'envio'"><i class="fas fa-shipping-fast"></i> Envío gratis</span>
                  </h6>
                  <h6 v-if="product.stock > 0" class="stock">{{ product.stock }} en stock</h6>
                  <h6 v-else style="color: gray;">Sin stock</h6>
                </div>
                <p class="p-button">
                  <a class="btn-more">Ver más</a>
                </p>
              </router-link>
            </div>

            <h5 v-if="!products.length && isLoading == false" style="text-align:center;">Aun no tenemos productos en esta categoría. ¿ Tienes un emprendimiento relacionado a esto?  <router-link to="/asociarse"> ¡Regístra tu negocio ! </router-link></h5>
          </div>

          <div class="row" v-if="pagination.total_products > 6">
            <div class="col-12 text-center">
              <div class="d-inline-block mx-0 col-pagination-sample-1">
                <b-pagination-nav :link-gen="linkGen" :number-of-pages="pagination.total" use-router></b-pagination-nav>
              </div>
            </div>
          </div>
        </div>
        <!--  -->

        <sweet-modal ref="modalLoading" hide-close-button blocking width="20%">
          <modalLoadingComponent></modalLoadingComponent>
              <p style="font-weight: 600; color: #291063; font-size: 1.1rem;">Cargando, espere ...</p>
        </sweet-modal>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      showFilters: false,
      showBanners: true,

      id:null,
      subid: null,
      subids:[],
      isLoading: true,

      products: [
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
      ],
      subcategories: [
        { id: 1, name: 'Anillos' },
        { id: 2, name: 'Collares' },
        { id: 3, name: 'Aretes' },
        { id: 4, name: 'Pulseras' },
        { id: 5, name: 'Oro' },
        { id: 6, name: 'Plata' },
      ],
      subcategoriesmexico:[],
      category: {
        name: '',
        id: 0,
      },

      selected: [],

      subcategory: {},

      pagination:{
        currentpage:1,
        total:1,
        total_products:1,
        number: 10
      },

      // == Carousels options ==
      subcategoriesOptions: {
        slidesPerView: 5,
        spaceBetween: 15,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1560: {
            slidesPerView: 5,
          },
          1300: {
            slidesPerView: 4,
          },
          992: {
            slidesPerView: 3,
          },
          578: {
            slidesPerView: 2,
          },
          1: {
            slidesPerView: 1,
          },
        }
      }
      // == ==
    }
  },

  methods: {
    getCatInfo: function(){
      axios.get(tools.url('/api/subcategories/'+this.id)).then((response)=>{
        //this.products = []; // AQUI LLENAR CON LOS PRODUCTOS YA QUE LA API TRAIGA ESA INFO
        this.category = response.data.category;
        this.showBanners = (!this.category.imageBanner1Url && !this.category.imageBanner2Url && !this.category.imageBanner3Url && !this.category.imageBanner4Url) ? false : true;
        this.subcategories = response.data.subcategories;
        this.$refs.modalLoading.close();
        this.isLoading = false;
      }).catch((error)=>{
        console.log(error);
        this.$refs.modalLoading.close();
      })

       // NOTA: la ruta "/api/subcategories/.." DEBE AHORA TRAER LOS PRODUCTOS TAMBIÉN PARA EVITAR USAR ESTE AXIOS
      axios.get(tools.url('/api/products/'+this.id+"?page=" + this.pagination.currentpage)).then((response)=>{
        this.products = response.data.products.data;

        this.pagination.total = response.data.products.last_page;
        this.pagination.total_products = response.data.products.total;
      }).catch((error)=>{
        console.log(error);
        this.$refs.modalLoading.close();
      })
    },

    /*getSubcatInfo: function(){
      //axios.get(tools.url('/api/productssubcategory/'+this.subid+"?page=" + this.pagination.currentpage)).then((response)=>{
       axios.post(tools.url('/api/productssubcategories?page=' + this.pagination.currentpage),{ids:this.subids}).then((response)=>{
        this.products = response.data.products.data;
        this.category = response.data.category;
        this.subcategories = response.data.subcategories;
        this.subcategory = response.data.subcategory;

        this.pagination.total = response.data.products.last_page;
        this.pagination.total_products = response.data.products.total;
      }).catch((error)=>{
        console.log(error);
        this.$refs.modalLoading.close();
      })
    },*/

    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    getSubcatInfo: function(){
      this.isLoading = true;
       axios.post(tools.url('/api/productssubcategories?page=' + this.pagination.currentpage),{ids:this.selected}).then((response)=>{
        this.products = response.data.products.data;
        this.category = response.data.category;
        this.showBanners = (!this.category.imageBanner1Url && !this.category.imageBanner2Url && !this.category.imageBanner3Url && !this.category.imageBanner4Url) ? false : true;
        //this.subcategories = response.data.subcategories;
        //this.subcategory = response.data.subcategory;

        this.pagination.total = response.data.products.last_page;
        this.pagination.total_products = response.data.products.total;
        this.isLoading = false;

      }).catch((error)=>{
        console.log(error);
        this.$refs.modalLoading.close();
      })
    },
    getSubcategoriesMexico:function(){
      axios.get(tools.url('/api/subcategories/34')).then((response)=>{
        this.subcategoriesmexico = response.data.subcategories;
      }).catch((error)=>{
      })
    },
    /*addsubcategory(indx){
       if (this.subids.length > 0) {

        if(!this.subids.includes(this.subcategories[indx]['id']))
        {
            this.subids.push(this.subcategories[indx]['id']);
            this.getSubcatInfo();
        }
        else{
            var newids = [];
            for (var i = 0; i < this.subids.length; i++) {
               if(this.subids[i] != this.subcategories[indx]['id'])
               {
                  newids.push(this.subids[i]);
               }
            }
            this.subids = newids;
            if (this.subids.length > 0) {
              this.getSubcatInfo();
            }

        }
      }
      else{
          this.subids.push(this.subcategories[indx]['id']);
          this.getSubcatInfo();
      }
    }, */

    run(){
      //this.selected = [];
      // Modal loading
      this.$refs.modalLoading.open();
      this.isLoading = true;

      // Pagination
      var pageNum = this.$route.query.page;
      this.pagination = {
        currentpage: 1,
        total:1,
        total_products:1,
        number: 10
      }

      if(pageNum) {
        this.pagination.currentpage = pageNum;
      }
      else{
        this.pagination.currentpage = 1;
      }




      // Si la ruta de la pagina tiene categoría (id) Y subcategoría (subid)
      //if(this.$route.params.id && this.$route.params.subid){
      if (this.selected.length > 0) {
        this.id= this.$root._getURLID(this.$route.params.id);
        //this.subid= this.$root._getURLID(this.$route.params.subid);
        this.getSubcatInfo();
      }
      else{ // Sino significa que solo tiene la categoría
        this.id= this.$root._getURLID(this.$route.params.id);
        this.subid= null;
        this.getCatInfo();
      }

      //this.getSubcatInfo();

       var self = this;
      setTimeout(()=>{
        self.$refs.modalLoading.close();
        self.isLoading = false;
      }, 2000);
    }
  },

  mounted(){
    console.log('111111111');
    this.getSubcategoriesMexico();
    this.run();
  },

  watch: {
    '$route':function(){
      this.run();
    },
    'selected':function(val){
      console.log('22222222');
      if(val.length > 0){
        if (this.category.id != 34) {
          this.$router.push('/categoria/'+this.$root._converToURL(this.category.name, this.category.id));
        }

        this.pagination.currentpage = 1;
        this.getSubcatInfo();
        console.log('333aaaaaa');
      }
      else{
        this.getCatInfo();
        console.log('333333bbbbbb');
      }
    }


  },
}
</script>
