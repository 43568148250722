<template lang="html">
  <div id="about-us-page-v2">
    <div class="img-bg" v-bind:style="{ backgroundImage: 'url(public/images/pages/about-us/image.jpg)' }"></div>

    <section class="container oversized-container main-section">
      <div class="row">
        <!-- <div class="col-lg-6 col-image">
          <img src="public/images/pages/about-us/image.jpg">
        </div> -->

        <div class="col-lg-6 text-justify col-info">
          <div class="mb-2 col-s-title">
            <h2 class="text-center title-s2">Nosotros</h2>
          </div>

          <div class="text-justify">
            <p>
              ¡<strong>ANLESA</strong>, es una empresa dedicada a la comercializacion de productos y servicios por medios Digitales!
            </p>
            <p class="mt-2">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>

            <p class="mt-2">
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </p>

            <ul class="f-w-500">
              <li>Lorem ipsum dolor sit amet, consectetur.</li>
              <li>Ut enim ad minim.</li>
              <li>Nostrud exercitation ullamco.</li>
            </ul>

            <p class="mt-3">
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      content: '',
      image: ''
    }
  },

  methods: {
    getContent: function(){
      axios.get(tools.url('/api/nosotros')).then((response)=>{
        this.content = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    }
  },

  beforeMount(){
    // this.getContent();
  }
}
</script>
