<template lang="html">
  <div id="description-page">
    <section class="breadcrumb-section">
      <div class="container oversized-container">
        <p v-for="(cat, catg) in product.categories" :key="catg">
          <span>Producto</span><span class="line">/</span><router-link :to="'/categoria/'+$root._converToURL(cat.category, cat.category_id)">{{ cat.category }}</router-link><span class="line">/</span><router-link :to="'/subcategoria/'+$root._converToURL(cat.subcategory, cat.subcategory_id)">{{ cat.subcategory }}</router-link>
        </p>
      </div>
    </section>

    <section class="container oversized-container description-section" id="product-description" v-if="isLoading == false">
      <div class="row">

        <div class="col-lg-6 col-gallery">
          <!-- <swiper class="carousel-lg" :options="galleryOptions" v-if="showGallery">
            <swiper-slide v-for="(row, galIndex) in gallery" :key="galIndex">
              <div class="image-container">
                <div class="img">
                  <v-zoom :img="row" :width="'100%'"></v-zoom>
                </div>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper> -->

          <swiper class="swiper gallery-lg" :options="swiperOptionTop" ref="swiperTop">
            <swiper-slide v-for="(row, galIndex) in gallery" :key="galIndex">
              <div class="image-container">
                <div class="img">
                  <v-zoom :img="row" :width="'100%'"></v-zoom>
                </div>
              </div>
            </swiper-slide>

            <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
            <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
          </swiper>
          <!-- swiper2 Thumbs -->

          <!-- <div class="mt-3 box-gallery-th">
            <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
              <swiper-slide v-for="(row, gal2Index) in gallery" :key="'gal2Index-'+gal2Index">
                <div class="box-image">
                  <div class="img" v-bind:style="{ backgroundImage: 'url('+row+')' }"></div>
                </div>
              </swiper-slide>
            </swiper>
          </div> -->

        </div>

        <b-form class="col-lg-5 col-description" @submit="addCart">
          <h1 class="name">{{ product.name }}</h1>
          <h6 class="sku" v-if="product.type == 'producto'">Clave Articulo: {{ product.sku }}</h6>
          <h6 class="sku" v-if="product.type == 'servicio'">Clave Servicio: {{ product.sku }}</h6>

          <div class="mt-2" v-if="product.price_discount != null && product.price_discount > 0">
            <h6 class="price discount" v-if="product.price_discount != null">
              <span>$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price_orig) }}</span>
            </h6>
            <h4 class="price" v-if="product.price_discount != null">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price_discount) }}</h4>
            <h4 class="price" v-else>$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }}</h4>

            <div class="note">
              <span v-if="product.price_discount != null"> ({{product.discount}}% de descuento)</span>
              <span v-if="product.free_shipping == 1"> (Envío gratis)</span>
            </div>
          </div>

          <div v-else>
            <h4 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }}</h4>
            <span v-if="product.free_shipping == 1"> (Envío gratis)</span>
          </div>
          <!-- <router-link class="t-250 box-btn-bubble pink mr-lg-2" :to="'/emprendedor/'+$root._converToURL(product.entrepreneur, product.users_id)">
            <i class="far fa-store icon"></i> {{ product.entrepreneur }}
          </router-link>
          <br> -->

          <!-- <span style="font-weight: 500;"> {{ product.entrepreneur_town }}, {{ product.entrepreneur_state }}</span> -->
          <!-- <a class="t-250 btn-seller whatsapp" v-if="product.entrepreneur_phone" target="_blank" :href="'https://wa.me/52'+product.entrepreneur_phone">
            <i class="fab fa-whatsapp icon"></i> Contactar por WhatsApp
          </a> -->

          <div class="mt-3 col-12 px-0 col-rating-form" v-if="product.canrating == true">
            <b-form class="box-rating-form" @submit="addRating">
              <h5 class="subt">Califica este creador</h5>

              <div class="box-stars">
                <b-form-rating inline required v-model="stars" stars="5"></b-form-rating>
              </div>

              <div class="box-input">
                <p class="mt-1">
                  <b-button type="submit" class="btn-co">Calificar</b-button>
                </p>
              </div>
            </b-form>
          </div>

          <!-- <div class="mt-3 txt-description"  v-html="product.short_description"></div> -->

          <h6 class="sku" v-if="product.video_url"><a :href="product.video_url">{{product.video_ur}}</a> </h6>

          <h6 class="subtitle" v-if="product.stocktype == 'sizes'">Tallas</h6>
          <h6 class="subtitle" v-else-if="product.stocktype == 'models'">Modelos</h6>

          <div class="table-responsive" v-if="product.models.length > 0">
            <table class="table table-sm" id="tablemodels">
              <thead>
                <tr>
                  <th class="p-0"></th>
                  <th class="cell-img">Imagen</th>
                  <th style="width: 110px" v-if="product.stocktype == 'sizes' || product.stocktype == 'sizesmodels'">Talla</th>
                  <th v-if="product.stocktype == 'models' || product.stocktype == 'sizesmodels'">Modelo</th>
                  <th>Descripción</th>
                  <th>Existencia</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(model,indx) in product.models" :key="indx" >
                  <input class="fake-radio" type="radio" :id="'model'+indx" :name="model.id" :value="model.id" v-model="form.modelo" @click="changeModelid(indx,model.id)">
                  <!-- <td>{{model.id}}</td> -->
                  <td class="cell-img">
                    <img :src="model.imageUrl" class="t-150 img-fluid" @click="openModalGallery([model.imageUrl])">
                  </td>
                  <td v-if="product.stocktype == 'sizes' || product.stocktype == 'sizesmodels'">{{model.size}}</td>
                  <td v-if="product.stocktype == 'models' || product.stocktype == 'sizesmodels'">{{model.model}}</td>
                  <td>{{model.description}}</td>
                  <td>{{model.stock}}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h6 class="mt-2 subtitle" v-b-toggle="'collapse-2'">Descripción <small class="fas fa-chevron-down"></small></h6>
          <b-collapse visible class="mt-1" id="collapse-2">
            <div class="txt-description"  v-html="product.description"></div>
          </b-collapse>


          <div v-if="product.disabled == false">
              <div v-if="product.stocktype == 'productunique'">
                  <h6 class="mt-3 subtitle" v-if="stock > 0">Stock disponible: <span style="color:#009d37;font-size: 25px">{{stock}}</span> </h6>
                  <h6 class="mt-3 subtitle" v-else>Sin stock<div class="out-of-stock" style="background-color: #f43c3c;color: #fff;font-weight: 500;padding: 4px 14px 5px;right: 0;text-align: center;width: 108px;">Agotado</div></h6>
              </div>
              <div v-else>
                <div v-if="showbtnvcartmodel == true">
                    <h6 class="mt-3 subtitle" v-if="stock > 0">Stock disponible: <span style="color:#009d37;font-size: 25px">{{stock}}</span> </h6>
                    <h6 class="mt-3 subtitle" v-else>Sin stock<div class="out-of-stock" style="background-color: #f43c3c;color: #fff;font-weight: 500;padding: 4px 14px 5px;right: 0;text-align: center;width: 108px;">Agotado</div></h6>
                </div>

              </div>

          </div>




          <div class="d-block mt-4 mb-2" v-if="product.documentUrl">
            <a class="btn btn-submit btn-contact" v-if="product.documentUrl" :href="product.documentUrl" target="_blank" rel="noopener noreferrer">Descargar ficha técnica <i class="fa fa-file-pdf-o" aria-hidden="true"></i></a>
          </div>

          <!-- <h6 class="mt-2 mb-1 subtitle" v-if="product.tablaPrecios">Lista de precios</h6>
          <img class="img-fluid" :src="product.tablaPrecios"> -->


              <!-- <h6 class="mt-3 subtitle" v-if="product.delivery_time_custom && product.type == 'producto'">Tiempo de entrega</h6>
              <h4 class="delivery-time" v-if="product.delivery_time_custom && product.type == 'producto'">{{ product.delivery_time_custom }}</h4> -->

            <h4 class="delivery-time" v-if="product.type == 'servicio'">El proveedor de este servicio se coordinará con el comprador para definir fecha y hora de inicio del servicio</h4>


          <div v-if="product.disabled == false">
              <div v-if="product.stocktype == 'productunique'">
                <div class="col-quantity2 mt-2" v-if="stock > 0" >
                    <a class="form-control btn-q" @click="changeCantidad('-')">
                      <i class="fas fa-minus"></i>
                    </a>
                    <b-form-input class="input-q" type="number" min="0" v-model="form.quantity" @keypress="isNumber($event)" @paste="noPaste" />
                    <a class="form-control btn-q" @click="changeCantidad('+')">
                      <i class="fas fa-plus"></i>
                    </a>
                </div>
              </div>
              <div v-else>
                  <div v-if="showbtnvcartmodel == true">
                    <div class="col-quantity2 mt-2" v-if="stock > 0" >
                        <a class="form-control btn-q" @click="changeCantidad('-')">
                          <i class="fas fa-minus"></i>
                        </a>
                        <b-form-input class="input-q" type="number" min="0" v-model="form.quantity" @keypress="isNumber($event)" @paste="noPaste" />
                        <a class="form-control btn-q" @click="changeCantidad('+')">
                          <i class="fas fa-plus"></i>
                        </a>
                    </div>
                  </div>
              </div>

          </div>

          <div v-if="product.type == 'producto'">
              <div class="d-block mt-4 mb-2" v-if="product.stocktype == 'productunique'">
                <div v-if="product.disabled == false">
                    <b-button v-if="stock > 0" type="submit" class="my-2 my-xl-0 btn-submit"><i class="fal fa-shopping-bag mr-1"></i> Agregar al carrito</b-button>
                </div>
                <div class="special-no-slot" v-else>
                  <span class="box-btn-bubble blue"><i class="far fa-times icon"></i> Producto temporalmente no disponible para la venta.</span>
                  <button type="button" class="btn btn-primary btn-mdl" @click="openModalInterested" >Avísale a este creador que te interesa</button>
                </div>
              </div>

              <div class="d-block mt-4 mb-2" v-else-if="product.stocktype == 'sizes' || product.stocktype == 'models' || product.stocktype == 'sizesmodels'">
                <div v-if="showbtnvcartmodel == true">
                  <b-button type="submit" class="my-2 my-xl-0 btn-submit" v-if="stock > 0"><i class="fal fa-shopping-bag mr-1"></i> Agregar al carrito</b-button>

                </div>
                <div v-else>
                  <span class="box-btn-bubble blue" v-if="form.modelo != null"><i class="far fa-times icon"></i> Producto temporalmente no disponible para la venta</span>
                  <span v-else class="box-btn-bubble blue" style="border-color: #e91f78;color: #e91f78;">
                    <span v-if="product.stocktype == 'sizes'">Selecciona una Talla para ver su disponibilidad</span>
                    <span v-else-if="product.stocktype == 'models'">Selecciona un Modelo para ver su disponibilidad</span>
                    <span v-else-if="product.stocktype == 'sizesmodels'">Selecciona una Talla/Modelo para ver su disponibilidad</span>
                    <span v-else></span>
                  </span>
                </div>

              </div>
          </div>

        </b-form>

        <div class="col-12 mt-4 mb-2">
          <div class="col-12 px-0 col-s-title">
            <h2 class="title-s2">Pregunta al creador</h2>
            <hr class="mt-2 mb-0" />
          </div>
        </div>

        <div class="col-lg-6 col-comment-form">
          <div class="col-12 col-comments">
            <b-form class="box-comment" @submit="addComment">
              <div class="box-input">
                <b-form-textarea v-model="comment.body" placeholder="Escribe tu comentario" required rows="2" max-rows="2"></b-form-textarea>

                <p class="mt-2 text-right">
                  <b-button type="submit" class="btn-co">Enviar pregunta</b-button>
                </p>
              </div>
            </b-form>
          </div>
        </div>
        <div class="col-lg-6 mt-3 col-all-comments">
          <div class="wr-comments" v-if="product.comments.length">
            <div class="row">
              <div class="col-12 col-comments">
                <h6 class="mt-2 subtitle f-w-700">Preguntas de otros usuarios</h6>
                <div class="row">
                  <div class="col-lg-12 col-comment" v-for="(item, coprInx) in product.comments" :key="'coprInx-'+coprInx">
                    <div class="box" v-if="item.response != null">

                      <div class="box-comment f-w-600 text-uppercase">{{ item.comment }}</div>
                      <div class="box-comment d-block pl-3" v-if="item.response">- {{ item.response }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container oversized-container related-products-section" id="related-products" v-if="isLoading == false">
      <div class="row">
        <div class="col-12 px-sm-0 col-s-title">
          <h2 class="title-s2">Productos relacionados</h2>
          <hr class="mt-2 mb-2" />
        </div>
      </div>

      <div class="row">
        <div class="col-12 px-sm-0 col-products">
          <swiper class="swiper carousel-t1 ct1-white" :options="productsOption">
            <swiper-slide v-for="(product, index) in products" :key="index">
              <div class="col-12 px-0 sample-product shad-prod">
                <router-link class="outlined-box" :to="'/producto/'+$root._converToURL(product.name, product.id)">
                  <div class="box-image">
                    <!-- <div class="out-of-stock" v-if="product.stock < 1">Agotado</div> -->

                    <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.img+')' }">
                      <img src="public/images/shared/product.png" >
                    </div>
                  </div>

                  <div class="box-shot-descr">
                    <h6 class="sku">{{ product.sku }}</h6>
                    <h6 class="name para-5">{{ product.name }}</h6>
                    <h6 class="brand">{{ product.category }}</h6>

                    <h6 class="price" v-bind:class="{ 'discount' : product.activate_discount }" style="min-height: 51px;">
                        <!-- PRECIO TACHADO SIN DESCUENTO-->
                        <span class="cross" v-if="product.type_disc == 'descuento_envio' || product.type_disc == 'descuento'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }} </span>
                        <!-- PRECIO NORMAL-->
                        <span v-else-if="product.type_disc == null || product.type_disc == 'envio'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }} </span>
                        <!-- PRECIO FINAL CON DESCUENTO-->
                        <span v-if="product.type_disc == 'descuento_envio' || product.type_disc == 'descuento'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format( product.price_discount) }}</span>
                        <!-- ENVIO GRATIS-->
                        <span class="free-shipping" v-if="product.type_disc == 'descuento_envio' || product.type_disc == 'envio'"><i class="fas fa-shipping-fast"></i> Envío gratis</span>
                    </h6>
                    <h6 v-if="product.stock > 0" class="stock">{{ product.stock }} en stock</h6>
                    <h6 v-else style="color: gray;">Sin stock</h6>
                  </div>
                  <p class="p-button">
                    <a class="btn-more">Ver más</a>
                  </p>
                </router-link>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>

    </section>
     <sweet-modal blocking hide-close-button  ref="modalloading">
        <modalLoadingComponent></modalLoadingComponent>
        <p style="font-weight: 600; color: #291063; font-size: 1.1rem;">Cargando, espere ...</p>
    </sweet-modal>

    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
        <div v-html="modal.msg"></div>
        <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
            <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close()">Agregar mas productos</b-button>
            <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close(); $router.push('/cart')">Ir al carrito <i class="color-white fas fa-shopping-cart"></i></b-button>
        </div>
    </sweet-modal>

    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modalOk">
        <div v-html="modal.msg"></div>

    </sweet-modal>

    <sweet-modal class="sweet-style-1" ref="modalInterested" title="Avísale al creador que te interesa su producto">
      <b-form class="box-rating-form" @submit="addInterested">
        <div class="row">
              <b-form-group class="cus-f-group-1 col-12" label="Nombre completo">
                <b-form-input type="text" v-model="formInterested.name" required placeholder=""></b-form-input>
              </b-form-group>

              <b-form-group class="cus-f-group-1 col-12" label="Correo electrónico">
                <b-form-input type="email" v-model="formInterested.email" required placeholder=""></b-form-input>
              </b-form-group>

              <b-form-group class="cus-f-group-1 col-12" label="Mensaje">
                <b-form-textarea
                  v-model="formInterested.msg"
                  placeholder=""
                  rows="5"
                  max-rows="5"
                ></b-form-textarea>
              </b-form-group>

              <div class="col-12">
                <b-button type="submit" class="btn-s1 b-pink">ENVIAR</b-button>
              </div>
            </div>
      </b-form>

    </sweet-modal>

    <b-modal modal-class="modal-product-gallery" size="lg" ref="modal-image" title="" @hidden="showModalGallery = false" centered hide-footer>
      <swiper class="swiper" :options="modalGalleryOptions" v-if="showModalGallery">
        <swiper-slide v-for="(imgURL, imgModInx) in modalGallery" :key="'imgModInx-'+imgModInx">
          <img src="public/images/shared/empty.png" v-bind:style="{ backgroundImage: 'url('+imgURL+')' }">
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </b-modal>

  </div>
</template>

<script>


import vZoom from 'vue-zoom'; // https://www.npmjs.com/package/vue-zoom
export default {
  components: {vZoom},

  data() {
    return {
      id: null,
      showGallery: true,
      modalGallery: [],
      showModalGallery: false,

      stars:null,

      form: {
        modelo: null,
        quantity: 1,
        color: null,
        models:[],
        modelo:null
      },

      product: {
        name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        type:'product',
        sku: 'D5F600R',
        price_discount: 934,
        price_orig: 1099,
        discount: '10',
        free_shipping: 1,
        models:[],
        comments:[],
        canrating:false
      },

      qtyPrices: [],
      category: [],
      products: [
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
      ],

      colors: [],
      gallery: [
        'public/images/pages/products/product-1.jpg',
        'public/images/pages/products/product-1.jpg',
        'public/images/pages/products/product-1.jpg',
      ],
      galleryOld: [],
      relatedProducts: [],

      comment:{
        body: null
      },

      isLoading: true,
      stock: null,

      modal:{
        msg:'',
        icon:'',
        block:false
      },

      // == Carrusel options ==
      swiperOptionTop: {
        loop: true,
        loopedSlides: 1, // looped slides should be the same
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptionThumbs: {
        loop: true,
        centeredSlides: true,
        loopedSlides: 1, // looped slides should be the same
        spaceBetween: 25,
        slidesPerView: 3,
        // touchRatio: 0.2,
        slideToClickedSlide: true,

        breakpoints: {
          576: {
            slidesPerView: 3,
            spaceBetween: 25,
          },
          1: {
            spaceBetween: 10,
          },
        },
      },

      galleryOptions: {
        loop: false,
        speed: 600,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }

        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false
        // }
      },

      productsOption: {
        loop: false,
        slidesPerView: 5,
        spaceBetween: 15,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1399: {
            slidesPerView: 5
          },
          1199: {
            slidesPerView: 4
          },
          991: {
            spaceBetween: 15,
            slidesPerView: 3
          },
          575: {
            spaceBetween: 15,
            slidesPerView: 2
          },
          1: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
        }
      },

      modalGalleryOptions: {
        loop: true,
        speed: 750,
        // effect: 'fade',

        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        },

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      // == ==
      showbtnvcartmodel:false,


      formInterested:{
        name:null,
        email:null,
        msg:null,
        products_id:null,
        models_id:null
      }
    }
  },

  watch: {
        "$route.params.id" : function(v){
            //this.id = this.$route.params.id;
            this.id = this.$root._getURLID(this.$route.params.id);
            this.getProduct();
        },
        "form.modelo" : function(v){
          this.showbtnvcartmodel = false;
          for (var i = 0; i < this.product.models.length; i++) {
            if(v == this.product.models[i].id){

              this.stock = this.product.models[i].stock;

              if (this.product.models[i].disabled == false) {
                this.showbtnvcartmodel = true;
              }
              break;
            }
          }
        },
    },

  methods:{
    mountGallery() {
      this.$nextTick(() => {
        const swiperTop = this.$refs.swiperTop.$swiper
        const swiperThumbs = this.$refs.swiperThumbs.$swiper
        swiperTop.controller.control = swiperThumbs
        swiperThumbs.controller.control = swiperTop
      });
    },

    openModalGallery(galleryArray){
      this.modalGallery = galleryArray;

      this.$refs['modal-image'].show();

      setTimeout(()=>{
        this.showModalGallery = true;
      },700);
    },

    getProduct(){
      this.isLoading = true;
      this.$refs.modalloading.open();
      this.showGallery = false;
      axios.get(tools.url('/api/product/' + this.id)).then((response)=>{
        this.product = [];
        this.gallery = [];

        this.product = response.data;
        this.gallery = response.data.gallery;
        this.swiperOptionTop.loopedSlides = this.gallery.length; // Necesario poner la cantidad exacta de imagenes que viene de la API
        this.swiperOptionThumbs.loopedSlides = this.gallery.length; // Necesario poner la cantidad exacta de imagenes que viene de la API
        this.category = response.data.category;
        if(this.product.stocktype != 'productunique'){
            if (this.product.models.length > 0) {
                this.form.modelo = this.product.models[0]['id'];
            }
        }
        else{
          this.stock = this.product.stock;
        }




        setTimeout(() => {
          this.mountGallery();
        }, 1000);

        setTimeout(() => {
          this.showGallery = true;
        }, 5000);

        this.getRelated(this.id);
        this.$refs.modalloading.close();
        this.isLoading = false;
      }).catch((error)=>{
        console.log(error);
        this.isLoading = false;
      });
    },

    getRelated(id){
      axios.get(tools.url('/api/products/related/'+id)).then((response)=>{
        this.products = response.data;

      }).catch((error)=>{
        console.log(error);
      })
    },


    addComment(event) {
      this.$refs.modalloading.open();
      event.preventDefault();
        axios.post(tools.url("/api/comments/"+this.product.id),{comment:this.comment.body}).then((response) => {
            this.comment.body = '';
            this.$refs.modalloading.close();
            this.getProduct();
        }).catch((error) => {
            alert("Error al agregar comentario");
            console.log(error);
        })
    },

    addCart(evt){
      evt.preventDefault();
      var cart = [];

      /*if (this.product.weight == '0.00' || this.product.weight == null || this.product.weight == 0) {
          alert('Este producto no se puede agregar al carrito');
          return;
      }
      if (this.product.width == '0.00' || this.product.width == null || this.product.width == 0) {
          alert('Este producto no se puede agregar al carrito');
          return;
      }
      if (this.product.longg == '0.00' || this.product.longg == null || this.product.longg == 0) {
          alert('Este producto no se puede agregar al carrito');
          return;
      }
      if (this.product.high == '0.00' || this.product.high == null || this.product.high == 0) {
          alert('Este producto no se puede agregar al carrito');
          return;
      }*/
      if (this.product.models.length > 0 && this.product.type == 'producto') {
          if (this.form.modelo == null) {

            if(this.product.stocktype == 'sizes'){
              alert('Selecciona una talla');
              return;
            }
            else if(this.product.stocktype == 'models'){
              alert('Selecciona un modelo');
              return;
            }
            else if(this.product.stocktype == 'sizesmodels'){
              alert('Selecciona una talla y modelo');
              return;
            }



          }
          else{
              if(localStorage.cart){
                  cart = JSON.parse(localStorage.getItem('cart'));

                  for(var i = 0; i < cart.length ; i++)
                  {
                      if(cart[i].id == this.id && cart[i].modelo == this.form.modelo)
                      {
                          var suma = cart[i].quantity + this.form.quantity;
                          if(suma > this.stock){
                             alert('La cantidad agregada supera el stock disponible, actualmente tienes '+cart[i].quantity+' agregados');
                              return;
                          }
                          cart[i].quantity = suma <= this.stock ? suma : this.stock;
                          encontrado = true;
                          break;
                      }
                  }

                  if(!encontrado)
                  {
                      if(this.form.quantity > this.stock){
                          alert('La cantidad agregada supera el stock disponible');
                          return;
                      }
                      cart.push({ id:this.id, quantity:this.form.quantity, modelo: this.form.modelo, color: this.form.color, imageUrl: this.gallery[0] });
                  }
              }
              else
              {
                  if(this.form.quantity > this.stock){
                      alert('La cantidad agregada supera el stock disponible');
                      return;
                  }
                  cart.push({ id:this.id, quantity:this.form.quantity, modelo: this.form.modelo, imageUrl: this.gallery[0] });
              }
          }
      }
      else{
          if(localStorage.cart){
                  cart = JSON.parse(localStorage.getItem('cart'));

                  for(var i = 0; i < cart.length ; i++)
                  {
                      if(cart[i].id == this.id && cart[i].modelo == this.form.modelo)
                      {
                          var suma = cart[i].quantity + this.form.quantity;
                          if(suma > this.stock){
                             alert('La cantidad agregada supera el stock disponible, actualmente tienes '+cart[i].quantity+' agregados');
                              return;
                          }
                          cart[i].quantity = suma <= this.stock ? suma : this.stock;
                          encontrado = true;
                          break;
                      }
                  }

                  if(!encontrado)
                  {
                      if(this.form.quantity > this.stock){
                          alert('La cantidad agregada supera el stock disponible');
                          return;
                      }
                      cart.push({ id:this.id, quantity:this.form.quantity, modelo: this.form.modelo, imageUrl: this.gallery[0] });
                  }
              }
              else
              {
                  if(this.form.quantity > this.stock){
                      alert('La cantidad agregada supera el stock disponible');
                      return;
                  }
                  cart.push({ id:this.id, quantity:this.form.quantity, modelo: this.form.modelo, imageUrl: this.gallery[0] });
              }
      }

      var encontrado = false;

      var quantity_all = 0;
      cart.forEach(function(objeto) {
        quantity_all += objeto.quantity;
      });
      this.$root.cartCount = quantity_all;

      localStorage.setItem("cart",JSON.stringify(cart));


      const now = new Date();
      const item = {timestamp: now.getTime()};
      localStorage.setItem('created_cart', JSON.stringify(item));


      //this.toast();
      this.modal.msg = 'El producto <b>'+this.product.name+'</b> se agregado al carrito';
      this.modal.icon = 'success';
      this.$refs.modal.open();
    },
    noPaste(evt){
      event.preventDefault();
    },

    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    changeCantidad($type){
      this.form.quantity = parseInt(this.form.quantity);
      if ($type == '-') {
        this.form.quantity = (this.form.quantity > 1) ? --this.form.quantity : 1;
      }
      else{
        this.form.quantity = (this.form.quantity < 100) ? ++this.form.quantity : 100;
      }
    },
    changeModelid(indx,id){
        if (this.form.modelo == id) {
            this.form.modelo = null;
            this.stock = 0;
        }
    },

    addRating(evt){
      evt.preventDefault();
        var formdata = {
          products_id:this.id,
          stars:this.stars
        };
        axios.post(tools.url('/api/storeEntrepreneursRating'),formdata).then((response)=>{
          this.modal.icon = 'success';
          this.modal.msg = 'Gracias por tu calificacion';
          this.$refs.modalOk.open();


        }).catch((error)=>{
          console.log(error);
          this.modal.icon = 'error';
          this.modal.msg = 'Error al guardar la calificacion';
          this.$refs.modalOk.open();
        })
    },
    openModalInterested(){
        this.formInterested.products_id = this.id;
        this.formInterested.models_id = this.form.modelo;
        this.formInterested.name = null;
        this.formInterested.email = null;
        this.formInterested.msg = null;
        this.$refs.modalInterested.open();
    },
    addInterested(evt){
      evt.preventDefault();

        axios.post(tools.url('/api/storeInterested'),this.formInterested).then((response)=>{
          this.$refs.modalInterested.close();
          this.modal.icon = 'success';
          this.modal.msg = 'Gracias, le avisaremos al creador';
          this.$refs.modalOk.open();


        }).catch((error)=>{
          this.$refs.modalInterested.close();
          console.log(error);
          this.modal.icon = 'error';
          this.modal.msg = 'Error al guardar la informacion';
          this.$refs.modalOk.open();
        })
     }
  },

   mounted(){
    this.id = this.$route.params.id;
    this.id = this.$root._getURLID(this.$route.params.id);
    // this.getProduct();
    this.isLoading = false;
  }
}
</script>
