<template lang="html">
  <div class="col-cart-data loggedin-cart">

    <div class="card card-1">
        <div class="card-body">
            <div class="row justify-content-between mb-3">
                <div class="col-auto">
                    <h6 class="color-1 mb-0 change-color">Recibo</h6>
                </div>
                <!-- <div class="col-auto "> <small>Estatus : {{ order.status }}</small> </div> -->
            </div>

            <div class="row" v-for="(detail, index) in orderDetail" :key="index" >
                <div class="col">
                    <div class="card card-2">
                        <div class="card-body">
                            <div class="media">
                                <div class="sq align-self-center ">
                                    <img class="img-fluid my-auto align-self-center mr-2 mr-md-4 pl-0 p-0 m-0" :src="detail.imageUrl" width="50" height="50" />
                                </div>
                                <div class="media-body my-auto text-right">
                                    <div class="row my-auto flex-column flex-md-row">
                                        <div class="col-6 ">
                                            <h6 class="mb-0">{{ detail.product.name }}</h6>
                                        </div>
                                        <div class="col-6"> <small>SKU: {{ detail.product.sku }} </small></div>
                                        <!-- <div class="col my-auto "> <small>Talla : L</small></div> -->
                                        <div class="col-6"> <small>Cantidad: {{ detail.quantity }}</small></div>
                                        <div class="col-3">
                                            <h6 class="mb-0">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(detail.total) }}</h6>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row my-auto flex-column flex-md-row">
                                        <div class="col-6 ">
                                             <b>{{ detail.courier }}</b>
                                        </div>
                                        <div class="col-6 ">
                                            Guia: <b>{{ detail.trackingNumber }}</b>
                                        </div>
                                        <div class="col-6 ">
                                            Status: <b>{{ detail.current_status }}</b>
                                        </div>
                                        <!-- <div class="col-6 ">
                                            Fecha estimada de entrega: <b>{{ detail.deliveryTimestamp }}</b>
                                        </div> -->
                                        <div class="col-6 ">
                                            <button style="padding: 1px;font-size: 14px;" class="btn btn-primary" v-if="detail.canrating == true" @click="addRatingModal(detail.product_id,detail.entrepeuner_name)"><i class="fas fa-star"></i>Calificar</button>
                                            <button style="padding: 1px;font-size: 14px;" class="btn btn-secondary" v-else ><i class="fas fa-star"></i>Calificar</button>
                                        </div>
                                        <div class="col-6 ">
                                            <button style="padding: 1px;font-size: 14px;" @click="addCart(detail.product_id,detail.modelo_id)" class="btn btn-success">Volver a comprar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col">
                    <div class="row justify-content-between">
                        <div class="col-auto">
                            <p class="mb-1 text-dark"><b>Detalles del pedido</b></p>
                        </div>
                        <div class="flex-sm-col text-right col">
                            <p class="mb-1"><b>Subtotal</b></p>
                        </div>
                        <div class="flex-sm-col col-auto">
                            <p class="mb-1">{{ order.subtotal }}</p>
                        </div>
                    </div>
                    <!-- <div class="row justify-content-between">
                        <div class="flex-sm-col text-right col">
                            <p class="mb-1"> <b>Descuento</b></p>
                        </div>
                        <div class="flex-sm-col col-auto">
                            <p class="mb-1">$150</p>
                        </div>
                    </div> -->
                   <!--  <div class="row justify-content-between">
                        <div class="flex-sm-col text-right col">
                            <p class="mb-1"><b>IVA 16%</b></p>
                        </div>
                        <div class="flex-sm-col col-auto">
                            <p class="mb-1">$ {{ order.tax }}</p>
                        </div>
                    </div> -->
                    <div class="row justify-content-between">
                        <div class="flex-sm-col text-right col">
                            <p class="mb-1"><b>Costo envío</b></p>
                        </div>
                        <div class="flex-sm-col col-auto">
                            <p class="mb-1">{{ order.shippingCost }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row invoice">
                <div class="col">
                    <p class="mb-1"> Número de pedido : MX000{{ order.id }}</p>
                    <p class="mb-1">Fecha del pedido : {{ order.created }}</p>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="jumbotron-fluid">
                <div class="row justify-content-between ">
                    <div class="col-auto my-auto ">
                        <h2 class="mb-0 font-weight-bold">TOTAL</h2>
                    </div>
                    <div class="col-auto my-auto ml-auto">
                        <h1 class="display-3 ">{{ order.total }}</h1>
                    </div>
                </div>
            </div>
        </div>

        <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
            <div v-html="modal.msg"></div>
            <button v-if="modal.typemodal == 'cart'" class="btn btn-success" @click="tocart" >Ir a carrito</button>
        </sweet-modal>
        <sweet-modal ref="modalRating">
            <b-form class="box-rating-form" @submit="addRating">
              <h5 class="subt">Califica este creador: <b>{{ formrating.entrepeuner_name }}</b></h5>

              <div class="box-stars">
                <b-form-rating inline required v-model="formrating.stars" stars="5"></b-form-rating>
              </div>

              <div class="box-input">
                <p class="mt-1">
                  <b-button type="submit" class="btn-co">Calificar</b-button>
                </p>
              </div>
            </b-form>

        </sweet-modal>
      </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
        id: null,
        order: [],
        orderDetail: [],
        modal:{
            msg:'',
            icon:'',
            block:false,
            typemodal:null
        },
        formrating:{
            products_id:null,
            stars:0,
            entrepeuner_name:null
        }

    }
  },

  methods: {

    getContent: function(id){
      axios.get(tools.url('/api/user/order/'+id)).then(({data}) =>{
        this.order = data.order;
        this.orderDetail = data.orderDetail;
      }).catch((error)=>{
        console.log(error);
       // this.$router.push("/");
      })
    },
    addCart(product_id,modelo_id){
        var cart = [];
        var encontrado = false;
        if(localStorage.cart){
            cart = JSON.parse(localStorage.getItem('cart'));

            for(var i = 0; i < cart.length ; i++)
            {
                if(cart[i].id == product_id && cart[i].modelo == modelo_id)
                {
                    var suma = cart[i].quantity + 1;
                    cart[i].quantity = suma;
                    encontrado = true;
                    break;
                }
            }

            if(!encontrado)
            {
                cart.push({ id:product_id, quantity:1, modelo: modelo_id});
            }
        }
        else
        {
            cart.push({ id:product_id, quantity:1, modelo: modelo_id });
        }

        localStorage.setItem("cart",JSON.stringify(cart));
        this.$root.cartCount = cart.length;
        //this.toast();
        this.modal.typemodal = 'cart';
        this.modal.msg = 'El producto fue agregado al carrito';
        this.modal.icon = 'success';
        this.$refs.modal.open();
    },
    tocart(){
        this.$router.push('/cart');
    },
    addRatingModal(products_id,entrepeuner_name){
        this.formrating.products_id = products_id;
        this.formrating.stars = 0;
        this.formrating.entrepeuner_name = entrepeuner_name;
        this.$refs.modalRating.open();
    },
    addRating(evt){
      evt.preventDefault();

        axios.post(tools.url('/api/storeEntrepreneursRating'),this.formrating).then((response)=>{
            this.$refs.modalRating.close();
          this.modal.icon = 'success';
          this.modal.msg = 'Gracias por tu calificacion';
          this.$refs.modal.open();
          
          this.getContent(this.$route.params.id);
        }).catch((error)=>{
          console.log(error);
          this.modal.icon = 'error';
          this.modal.msg = 'Error al guardar la calificacion';
          this.$refs.modal.open();
        })
    }
    

  },

  mounted(){
    if(this.$route.params.id){
        this.id = this.$route.params.id;
        this.getContent(this.$route.params.id);
    }else{
        //this.$router.push("/");
    }
  },

  watch: {
    '$route':function(){
      if(this.$route.params.id){
        this.id = this.$route.params.id;
        this.getContent(this.$route.params.id);
      }
    }
  }

}
</script>
