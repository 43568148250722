<template lang="html">
  <div id="home-page">

    <section class="container-fluid banner-section">
      <swiper class="swiper-desktop" :options="bannersOption">
        <swiper-slide v-for="(banner, index) in banners.pc" :key="index">
          <a class="d-block placed-backg" v-bind:style="{ backgroundImage: 'url('+banner.imagen+')' }" :href="banner.url">
            <img src="public/images/pages/home/banner.png">
          </a>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>

      <swiper class="swiper-mobile" :options="bannersOption">
        <swiper-slide  v-for="(banner, index) in banners.movil" :key="index">
          <a class="d-block placed-backg" v-bind:style="{ backgroundImage: 'url('+banner.imagen+')' }" :href="banner.url">
            <img src="public/images/pages/home/banner-m.png">
          </a>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>

    <section class="categories-v2-section">
      <div class="container">
        <div class="row main-row">
          <div class="col-12 col-title">
            <h2 class="title-s1">Categorías</h2>
          </div>

          <div class="col-12">
            <div class="row">
              <div class="col-12 col-lg-6 col-xl-4 col-categories" v-for="(c, chInx) in categories" :key="'chInx-'+chInx">
                <div class="content">
                  <h5 class="category-name">{{ c.name }}</h5>

                  <div class="box-subcategories">
                    <div class="col-12 col-subcategory" v-if="c.supersubcategory != null">
                      <router-link class="t-150 box-subcat" :to="'/subcategoria/'+$root._converToURL(c.supersubcategory.name, c.supersubcategory.id)">
                        <div class="placed-backg image xl" v-bind:style="{ backgroundImage: 'url('+c.supersubcategory.imageUrl+')' }">
                          <img src="public/images/shared/product-lg-1.png">
                        </div>

                        <h6>{{ c.supersubcategory.name }}</h6>
                      </router-link>
                    </div>

                    <div class="col-6 col-subcategory" v-for="(s, shInx) in c.subcategories" v-else>
                      <router-link class="t-150 box-subcat" :to="'/subcategoria/'+$root._converToURL(s.name, s.id)">
                        <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url('+s.imageUrl+')' }">
                          <img src="public/images/shared/product.png">
                        </div>

                        <h6>{{ s.name }}</h6>
                      </router-link>
                    </div>

                    <div class="col-12 col-more">
                      <router-link :to="'/subcategoria/'+$root._converToURL(c.supersubcategory.name, c.supersubcategory.id)" v-if="c.supersubcategory != null">Ver más</router-link>
                      <router-link :to="'/categoria/'+$root._converToURL(c.name, c.id)" v-else>Ver más</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="categories-section">
      <swiper class="swiper" :options="categoriesOptions">
        <swiper-slide v-for="(c, ch) in categories" :key="'ch-'+ch">
          <router-link v-if="c.subcategories.length" class="t-250 box-category" :to="'/categoria/'+$root._converToURL(c.name, c.id)" v-bind:style="{ backgroundImage: 'url('+c.imageUrl+')' }" style="background-size:cover !important;">
            <div class="box">
              <div class="inside">
                <h5>{{ c.name }}</h5>
              </div>
            </div>

            <img class="empty" src="public/images/shared/empty.png">
          </router-link>

           <router-link v-else class="t-250 box-category" :to="'/categoria/'+$root._converToURL(c.name, c.id)" v-bind:style="{ backgroundImage: 'url('+c.imageUrl+')' }" style="background-size:cover !important;">
            <div class="box">
              <div class="inside">
                <h5>{{ c.name }}</h5>
              </div>
            </div>

            <img class="empty" src="public/images/shared/empty.png">
          </router-link>

        </swiper-slide>
      </swiper>
    </section>

    <section class="register-section-s1">
      <router-link class="t-250 btn-register btn-green" to="/asociarse">Regístra tu Pyme y comienza a vender</router-link>
    </section>

    <section class="container oversized-container mb-4 products-section">
      <div class="row">
        <div class="col-12 mb-1 col-title">
          <h2 class="title-s1">PRODUCTOS</h2>
        </div>

        <div class="col-12 col-products">
          <swiper class="swiper carousel-t1 ct1-white" :options="productsOption">
            <swiper-slide v-for="(product, prhInx) in products" :key="'prhInx-'+prhInx">
              <div class="col-12 px-0 sample-product shad-prod">
                <router-link class="outlined-box" :to="'/producto/'+$root._converToURL(product.name, product.id)">
                  <div class="box-image">
                    <!-- <div class="out-of-stock" v-if="product.stock < 1">Agotado</div> -->

                    <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.img+')' }">
                      <img src="public/images/shared/product.png" >
                    </div>
                  </div>

                  <div class="box-shot-descr">
                    <h6 class="sku">{{ product.sku }}</h6>
                    <h6 class="name para-5">{{ product.name }}</h6>
                    <h6 class="brand">{{ product.category }}</h6>

                    <h6 class="price" v-bind:class="{ 'discount' : product.activate_discount }" style="min-height: 51px;">
                        <!-- PRECIO TACHADO SIN DESCUENTO-->
                        <span class="cross" v-if="product.type_disc == 'descuento_envio' || product.type_disc == 'descuento'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }} </span>
                        <!-- PRECIO NORMAL-->
                        <span v-else-if="product.type_disc == null || product.type_disc == 'envio'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }} </span>
                        <!-- PRECIO FINAL CON DESCUENTO-->
                        <span v-if="product.type_disc == 'descuento_envio' || product.type_disc == 'descuento'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format( product.price_discount) }}</span>
                        <!-- ENVIO GRATIS-->
                        <span class="free-shipping" v-if="product.type_disc == 'descuento_envio' || product.type_disc == 'envio'"><i class="fas fa-shipping-fast"></i> Envío gratis</span>
                    </h6>
                    <h6 v-if="product.stock > 0" class="stock">{{ product.stock }} en stock</h6>
                    <h6 v-else style="color: gray;">Sin stock</h6>
                  </div>
                  <p class="p-button">
                    <a class="btn-more">Ver más</a>
                  </p>
                </router-link>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>

    <section class="container oversized-container mb-4 mid-banners-section">
      <div class="row">
        <div class="col-lg-6 col-banner" v-for="(ban,indx) in banners.secundarios_superior">
          <a class="placed-backg" v-bind:style="{ backgroundImage: 'url('+ban.imagen+')' }" :href="ban.url">
            <img src="public/images/pages/home/banner-mid.png">
          </a>
        </div>

       <!--  <div class="col-lg-6 col-banner">
          <a class="placed-backg" v-bind:style="{ backgroundImage: 'url(public/images/pages/home/banner-mid.jpg)' }" href="#">
            <img src="public/images/pages/home/banner-mid.png">
          </a>
        </div> -->
      </div>
    </section>

    <section class="container oversized-container extra-info-section">
      <div class="row justify-content-between">
        <div class="col-12 col-title">
          <h2 class="text-center title-s1">Descubre Anlesa</h2>
        </div>

        <div class="col-4 col-box" data-aos="fade-up">
          <div class="inside">
            <div class="box-image">
              <img src="public/images/pages/home/icon-1.svg">
            </div>

            <div class="box-text">
              <div>
                <h6>Envíos gratis.</h6>
              </div>
            </div>
          </div>
        </div>

        <div class="col-4 col-box" data-aos="fade-up" data-aos-delay="50">
          <div class="inside">
            <div class="box-image">
              <img src="public/images/pages/home/icon-2.svg">
            </div>

            <div class="box-text">
              <div>
                <h6>Compras seguras.</h6>
              </div>
            </div>
          </div>
        </div>

        <div class="col-4 col-box" data-aos="fade-up" data-aos-delay="100">
          <div class="inside">
            <div class="box-image">
              <img src="public/images/pages/home/icon-3.svg">
            </div>

            <div class="box-text">
              <div>
                <h6>Asistencia Inmediata.</h6>
              </div>
            </div>
          </div>
        </div>

        <div class="col-4 col-box" data-aos="fade-up" data-aos-delay="150">
          <div class="inside">
            <div class="box-image">
              <img src="public/images/pages/home/icon-4.svg">
            </div>

            <div class="box-text">
              <div>
                <h6>Opciones de pago.</h6>
              </div>
            </div>
          </div>
        </div>

        <div class="col-4 col-box" data-aos="fade-up" data-aos-delay="200">
          <div class="inside">
            <div class="box-image">
              <img src="public/images/pages/home/icon-5.svg">
            </div>

            <div class="box-text">
              <div>
                <h6>Productores Mexicanos.</h6>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section class="categories-section">
      <swiper class="swiper" :options="categoriesOptions">
        <swiper-slide v-for="(c, ch) in subcategories" :key="'ch-'+ch">
          <router-link class="t-250 box-category" :to="'/subcategoria/'+$root._converToURL(c.name, c.id)" v-bind:style="{ backgroundImage: 'url('+c.imageUrl+')' }">
            <div class="box">
              <div class="inside">
                <h5>{{ c.name }}</h5>
              </div>
            </div>

            <img class="empty" src="public/images/shared/empty.png">
          </router-link>
        </swiper-slide>
      </swiper>
    </section>

    <section class="blog-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-12 mb-3 text-center col-title">
            <h2 class="title-s1">Blog</h2>
          </div>

          <div class="col-12 col-articles">
            <swiper class="swiper" :options="blogOptions">
              <swiper-slide v-for="(article, ahInx) in blogs" :key="'ahInx-'+ahInx">
                <div class="col-12 px-0 article-sample-1">
                  <router-link class="t-250 placed-backg article-link" v-bind:style="{ backgroundImage: 'url('+article.imageUrl+')' }" :to="'/blog/'+article.id">
                    <img class="empty" src="public/images/shared/empty.png">

                    <div class="inside">
                      <div class="t-250 show">Ver más</div>
                      <div class="shadow"></div>
                      <div class="box-txt">
                        <h5>{{article.title}}</h5>
                        <!-- <h6>Hoy, hace 1 hora.</h6> -->

                        <!-- <div class="descr">
                          <p>
                            Descripción breve del comunicado para pactar el interés del usuario.
                          </p>
                        </div> -->
                      </div>
                    </div>
                  </router-link>
                </div>
              </swiper-slide>


              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="container oversized-container mb-3 brands-section">
      <div class="row">
        <div class="col-12 mb-2 col-title">
          <h2 class="text-center title-s1">Ya están con nosotros</h2>
        </div>

        <div class="col-12 col-brands">
          <swiper class="swiper" :options="brandsOptions">
            <swiper-slide v-for="(entrepreneur, brhInx) in entrepreneurs" :key="'brhInx-'+brhInx">
              <div class="box-brand">
                <a :href="entrepreneur.link">
                  <div class="inside">
                    <img :src="entrepreneur.imageUrl">
                  </div>
                </a>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section> -->

    <section class="container oversized-container mb-4 mid-banners-section" v-if="false">
      <div class="row">
        <div class="col-lg-6 col-banner" v-for="(ban,indx) in banners.secundarios_inferior">
          <a class="placed-backg" v-bind:style="{ backgroundImage: 'url('+ban.imagen+')' }" :href="ban.url">
            <img src="public/images/pages/home/banner-mid.png">
          </a>
        </div>
        <!--
        <div class="col-lg-6 col-banner">
          <a class="placed-backg" v-bind:style="{ backgroundImage: 'url(public/images/pages/home/banner-mid.jpg)' }" href="#">
            <img src="public/images/pages/home/banner-mid.png">
          </a>
        </div> -->
      </div>
    </section>

    <section class="register-section-s1 mb-0">
      <router-link class="t-250 btn-register btn-pink" to="/registrarse">¡Regístrate ya!</router-link>
    </section>

    <sweet-modal ref="modalLoading" hide-close-button blocking >
          <modalLoadingComponent></modalLoadingComponent>
              <p style="font-weight: 600; color: #291063; font-size: 1.1rem;">Cargando, espere ...</p>
        </sweet-modal>

  </div>
</template>

<script>
export default {
  data(){
    return{
      banners: {
        pc :[
          { url: null, imagen: 'public/images/pages/home/banner-1.jpg' },
          { url: null, imagen: 'public/images/pages/home/banner-1.jpg' },
        ],
        movil: [
          { url: null, imagen: 'public/images/pages/home/banner-1-m.jpg' },
          { url: null, imagen: 'public/images/pages/home/banner-1-m.jpg' },
        ]
      },
      categories:[],
      subcategories:[],
      products:[
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
      ],
      entrepreneurs:[],
      blogs: [
        { id: 1, imageUrl: 'public/images/pages/blog/article-1.jpg', title: 'Joyería Sostenible: Lo Mejor de la Moda Ética para Este Año' },
        { id: 2, imageUrl: 'public/images/pages/blog/article-2.jpg', title: 'Cómo Elegir la Joyería Perfecta para Cada Estilo Personal' },
        { id: 3, imageUrl: 'public/images/pages/blog/article-3.jpg', title: 'Los Secretos de la Joyería Atemporal: Inversiones que Nunca Pasan de Moda' },
        { id: 4, imageUrl: 'public/images/pages/blog/article-4.jpg', title: 'Tendencias en Joyería para 2024: Los Must-Have para Elevar Tu Estilo' },
      ],
      brands: [
        // { imageUrl: 'public/images/pages/home/brand-1.png' },
        // { imageUrl: 'public/images/pages/home/brand-2.png' },
        // { imageUrl: 'public/images/pages/home/brand-3.png' },
      ],

      categories: [
        { name: 'Categoría 1', imageUrl: 'public/images/pages/categories/c-1.jpg', subcategories: [
            { imageUrl: 'public/images/pages/categories/c1.jpg', name: 'Subcategoría x' },
            { imageUrl: 'public/images/pages/categories/c1.jpg', name: 'Subcategoría x' },
            { imageUrl: 'public/images/pages/categories/c1.jpg', name: 'Subcategoría x' },
            { imageUrl: 'public/images/pages/categories/c1.jpg', name: 'Subcategoría x' },
          ]
        },
        { name: 'Categoría 2', imageUrl: 'public/images/pages/categories/c-2.jpg', subcategories: [
            { imageUrl: 'public/images/pages/categories/c2.jpg', name: 'Subcategoría x' },
            { imageUrl: 'public/images/pages/categories/c2.jpg', name: 'Subcategoría x' },
            { imageUrl: 'public/images/pages/categories/c2.jpg', name: 'Subcategoría x' },
            { imageUrl: 'public/images/pages/categories/c2.jpg', name: 'Subcategoría x' },
          ]
        },
        { name: 'Categoría 3', imageUrl: 'public/images/pages/categories/c-3.jpg', subcategories: [
            { imageUrl: 'public/images/pages/categories/c3.jpg', name: 'Subcategoría x' },
            { imageUrl: 'public/images/pages/categories/c3.jpg', name: 'Subcategoría x' },
            { imageUrl: 'public/images/pages/categories/c3.jpg', name: 'Subcategoría x' },
            { imageUrl: 'public/images/pages/categories/c3.jpg', name: 'Subcategoría x' },
          ]
        },
        { name: 'Otra Categoría', imageUrl: 'public/images/pages/categories/c-4.jpg', subcategories: [
            { imageUrl: 'public/images/pages/categories/c4.jpg', name: 'Subcategoría x' },
            { imageUrl: 'public/images/pages/categories/c4.jpg', name: 'Subcategoría x' },
            { imageUrl: 'public/images/pages/categories/c4.jpg', name: 'Subcategoría x' },
            { imageUrl: 'public/images/pages/categories/c4.jpg', name: 'Subcategoría x' },
          ]
        },
        { name: 'Otra Categoría', imageUrl: 'public/images/pages/categories/c-5.jpg', subcategories: [
            { imageUrl: 'public/images/pages/categories/c5.jpg', name: 'Subcategoría x' },
            { imageUrl: 'public/images/pages/categories/c5.jpg', name: 'Subcategoría x' },
            { imageUrl: 'public/images/pages/categories/c5.jpg', name: 'Subcategoría x' },
            { imageUrl: 'public/images/pages/categories/c5.jpg', name: 'Subcategoría x' },
          ]
        },
        { name: 'Otra Categoría', imageUrl: 'public/images/pages/categories/c-6.jpg', subcategories: [
            { imageUrl: 'public/images/pages/categories/c6.jpg', name: 'Subcategoría x' },
            { imageUrl: 'public/images/pages/categories/c6.jpg', name: 'Subcategoría x' },
            { imageUrl: 'public/images/pages/categories/c6.jpg', name: 'Subcategoría x' },
            { imageUrl: 'public/images/pages/categories/c6.jpg', name: 'Subcategoría x' },
          ]
        },
      ],

      // == Carousel options ==
      bannersOption: {
        loop: true,
        speed:800,
        // effect: 'fade',

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
      },

      categoriesv2Options: {
        slidesPerView: 3,
        spaceBetween: 16,

        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        breakpoints: {
          1200: {
            slidesPerView: 3,
          },
          992: {
            slidesPerView: 2,
          },
          1: {
            slidesPerView: 1,
          },
        }
      },

      categoriesOptions: {
        loop: false,
        speed:800,
        slidesPerView: 5,
        spaceBetween: 15,

        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },

        breakpoints: {
          1600: {
            slidesPerView: 5,
          },
          1200: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 3,
          },
          576: {
            slidesPerView: 2,
          },
          1: {
            slidesPerView: 1,
          },
        }
      },

      productsOption: {
        loop: true,
        slidesPerView: 5,
        spaceBetween: 15,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1599: {
            slidesPerView: 5
          },
          1299: {
            slidesPerView: 4
          },
          991: {
            spaceBetween: 15,
            slidesPerView: 3
          },
          575: {
            spaceBetween: 15,
            slidesPerView: 2
          },
          1: {
            slidesPerView: 1
          },
        }
      },

      blogOptions: {
        slidesPerView: 4,
        spaceBetween: 30,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1300: {
            slidesPerView: 4,
          },
          992: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          1: {
            slidesPerView: 1,
          },
        }
      },

      brandsOptions: {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 20,

        autoplay: {
          delay: 4500,
          disableOnInteraction: false
        },

        breakpoints: {
          992: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          0: {
            slidesPerView: 1,
          },
        }
      },
      slidersname:{
        carrusel_one:null,
        carrusel_two:null,
        carrusel_three:null
      }

      // == ==
    }
  },
  methods:{
    getBanners(){
        axios.get(tools.url("/api/banners")).then((response)=>{
          this.banners=response.data;
        }).catch((error)=>{
        });
    },
    getCategories(){

      axios.get(tools.url("/api/categoriesFrontHome")).then((response)=>{
          this.categories = response.data;
          }).catch((error)=>{

        });
    },
    getBlogs(){

      axios.get(tools.url("/api/blogs")).then((response)=>{
          this.blogs = response.data;
          }).catch((error)=>{

        });
    },
    getProducts(){

      axios.get(tools.url("/api/productsRandom")).then((response)=>{
          this.products = response.data;
          }).catch((error)=>{

        });
    },
    getEntrepreneurs(){
      axios.get(tools.url("/api/entrepreneurshome")).then((response)=>{
          this.entrepreneurs = response.data;
          }).catch((error)=>{

        });
    },
    getSubcategories(){

      axios.get(tools.url("/api/subcategoriesFront")).then((response)=>{
          this.subcategories = response.data;
          }).catch((error)=>{

        });
    },
    getSliderName(){
				axios.get(tools.url("/api/sliders")).then((response)=>{
			    	this.slidersname = response.data;
			    }).catch((error)=>{
			    });
			},

  },

  mounted() {
      this.$refs.modalLoading.open();
      this.getSliderName();
      this.getBanners();
      this.getCategories();
      this.getBlogs();
      this.getProducts();
      this.getEntrepreneurs();
      this.getSubcategories();

      var slef = this;
      setTimeout(function(){
          slef.$refs.modalLoading.close();
      }, 2000);
  }
}
</script>
