<template lang="html">
  <div id="results-page">

    <section class="breadcrumb-section">
      <div class="container oversized-container">
        <span>Categorías</span>
        <span class="line">/</span>
        <span>{{ category.name }}</span>
        <span class="line">/</span>
        <span>{{ subcategory.name }}</span>
      </div>
    </section>

    <section class="container oversized-container products-section">
      <div class="row mb-3">
        <div class="col-12 col-page-title">
          <h2 class="title-s2">{{ subcategory.name }}</h2>
        </div>
      </div>

      <div class="row">
        <!-- Mobile filters -->
        <div class="col-12 col-filters-mobile">
          <div class="bg-filter-mv" v-if="showFilters == true" @click="showFilters = false"></div>
          <a class="btn-filters" @click="showFilters = !showFilters">
            <i class="fas fa-filter"></i><span>Ver filtros</span>
          </a>
        </div>
        <!--  -->

        <!-- Col filters -->
        <div class="col-lg col-filters" v-bind:class="{ 'show-filters': showFilters == true }">
          <h4 class="_title">
            <span style="color:black;"><b>Filtrar</b></span>
            <a class="btn-hide" @click="showFilters = false"><i class="fas fa-chevron-left"></i></a>
          </h4>

          <b-form class="filters-container">
            <div class="box-filters">
              <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  id="checkbox-group-2"
                  v-model="selected"
                  :aria-describedby="ariaDescribedby"
                  name="fil-2">
                  <b-form-checkbox
                    :value="subc.id"
                    v-for="(subc, subInx) in subcategories"
                    :key="'subInx-'+subInx">
                    {{ subc.name }}
                  </b-form-checkbox>
                </b-form-checkbox-group>

                <b-form-checkbox-group
                  id="checkbox-group-2"
                  v-model="selectedall"
                  :aria-describedby="ariaDescribedby"
                  name="fil-2">
                  <b-form-checkbox :value="true">Todas</b-form-checkbox>
                </b-form-checkbox-group>

                <!-- <br><span style="color:black;" v-if="category.id != 34"><b>Explora ANLESA</b><br><span style="font-size: 13px;">Filtro por procedencia del creador</span></span>
                <b-form-checkbox-group
                  id="checkbox-group-2"
                  v-model="selected"
                  :aria-describedby="ariaDescribedby"
                  name="fil-2" v-if="category.id != 34">
                  <b-form-checkbox
                    :value="subc.id"
                    v-for="(subc, subInx) in subcategoriesmexico"
                    :key="'subInx-'+subInx">
                    {{ subc.name }}
                  </b-form-checkbox>
                </b-form-checkbox-group> -->


              </b-form-group>
            </div>
          </b-form>
        </div>
        <!--  -->

        <!-- Results -->
            <div class="col-lg">
              <div class="row sample-products-wrapper xxl-4e">
                <div class="col-sm-6 col-lg-4 sample-product shad-prod" v-for="(product, index) in products" :key="index">
                  <router-link class="outlined-box" :to="'/producto/'+$root._converToURL(product.name, product.id)">
                    <div class="box-image">
                      <!-- <div class="out-of-stock" v-if="product.stock < 1">Agotado</div> -->

                      <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.img+')' }">
                        <img src="public/images/shared/product.png" >
                      </div>
                    </div>

                    <div class="box-shot-descr">
                      <h6 class="sku">{{ product.sku }}</h6>
                      <h6 class="name para-5">{{ product.name }}</h6>
                      <h6 class="brand">{{ product.category }}</h6>

                      <h6 class="price" v-bind:class="{ 'discount' : product.activate_discount }" style="min-height: 51px;">
                          <!-- PRECIO TACHADO SIN DESCUENTO-->
                          <span class="cross" v-if="product.type_disc == 'descuento_envio' || product.type_disc == 'descuento'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }} </span>
                          <!-- PRECIO NORMAL-->
                          <span v-else-if="product.type_disc == null || product.type_disc == 'envio'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }} </span>
                          <!-- PRECIO FINAL CON DESCUENTO-->
                          <span v-if="product.type_disc == 'descuento_envio' || product.type_disc == 'descuento'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format( product.price_discount) }}</span>
                          <!-- ENVIO GRATIS-->
                          <span class="free-shipping" v-if="product.type_disc == 'descuento_envio' || product.type_disc == 'envio'"><i class="fas fa-shipping-fast"></i> Envío gratis</span>
                      </h6>
                      <h6 v-if="product.stock > 0" class="stock">{{ product.stock }} en stock</h6>
                      <h6 v-else style="color: gray;">Sin stock</h6>
                    </div>
                    <p class="p-button">
                      <a class="btn-more">Ver más</a>
                    </p>
                  </router-link>
                </div>

                <h5 v-if="!products.length && isLoading == false" style="text-align:center;">Aun no tenemos productos en esta categoría. ¿ Tienes un emprendimiento relacionado a esto?  <router-link to="/asociarse"> ¡Regístra tu negocio ! </router-link></h5>
              </div>

              <div class="row" v-if="pagination.total_products > 6">
                  <div class="col-12 text-center">
                    <div class="d-inline-block mx-0 col-pagination-sample-1">
                      <b-pagination-nav :link-gen="linkGen" :number-of-pages="pagination.total" use-router></b-pagination-nav>
                    </div>
                  </div>
              </div>

            </div>
            <!--  -->
            <sweet-modal ref="modalLoading" hide-close-button blocking width="20%">
              <modalLoadingComponent></modalLoadingComponent>
                  <p style="font-weight: 600; color: #291063; font-size: 1.1rem;">Cargando, espere ...</p>
            </sweet-modal>

        <!--  -->

      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      showFilters: false,
      selected: [],
      selectedmexico:[],
      selectedall:false,

      id:null,
      isLoading: true,

      products: [
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
        { id: 1, img: 'public/images/pages/products/product-1.jpg', name: 'Aretes de Zafiro Azul en Oro Blanco: Brillo y Distinción para Momentos Especiales', sku: '4X2S3SH', stock: 13, price: '799', offer_price: '999.00', type_disc: 'envio', category: 'Aretes' },
      ],
      category: {
        name: '',
        id: 0,
      },
      category:{
        name:null
      },
      subcategory:{
        name:null,
        id:null
      },
      subcategories:[
        { id: 1, name: 'Anillos' },
        { id: 2, name: 'Collares' },
        { id: 3, name: 'Aretes' },
        { id: 4, name: 'Pulseras' },
        { id: 5, name: 'Oro' },
        { id: 6, name: 'Plata' },
      ],
      subcategoriesmexico:[],
      pagination:{
        currentpage:1,
        total:1,
        total_products:1,
        number: 10
      },
      first:false,

    }
  },

  methods: {
    /*getProducts: function(){
      this.$refs.modalLoading.open();
      this.isLoading = true;

      axios.get(tools.url('/api/productssubcategory/'+this.id+"?page=" + this.pagination.currentpage)).then((response)=>{
        this.products = response.data.products.data;
        this.category = response.data.category;
        this.subcategory = response.data.subcategory;
        this.subcategories = response.data.subcategories;

        var alls = {
            'id':this.category.id,
            'name':'Todas',
        };
        this.subcategories.push(alls);
        this.isLoading = false;

        this.pagination.total = response.data.products.last_page;
        this.pagination.total_products = response.data.products.total;

        this.$refs.modalLoading.close();
      }).catch((error)=>{
        console.log(error);
        this.$refs.modalLoading.close();
      })
    },*/

    /*getProductsAll(id){
      this.$refs.modalLoading.open();
      this.isLoading = true;

      axios.get(tools.url('/api/products/'+id+"?page=" + this.pagination.currentpage)).then((response)=>{
        this.products = response.data.products.data;
        this.category = response.data.category;
        this.subcategory.name = 'Todas';
        this.pagination.total = response.data.products.last_page;
        this.pagination.total_products = response.data.products.total;


        this.isLoading = false;
        this.$refs.modalLoading.close();
      }).catch((error)=>{
        console.log(error);
        this.$refs.modalLoading.close();
      })
    },*/
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    getSubcatInfo: function(){
      this.isLoading = true;
      this.$refs.modalLoading.open();
       axios.post(tools.url('/api/productssubcategories?page=' + this.pagination.currentpage),{ids:this.selected}).then((response)=>{
        this.products = response.data.products.data;
        this.category = response.data.category;
        if (this.first == false) {
          this.subcategories = response.data.subcategories;
        }

        this.subcategory = response.data.subcategory;

        this.pagination.total = response.data.products.last_page;
        this.pagination.total_products = response.data.products.total;
        this.isLoading = false;
        this.first = true;

        this.$refs.modalLoading.close();
      }).catch((error)=>{
        console.log(error);
        this.$refs.modalLoading.close();
      })
    },

    getSubcategoriesMexico:function(){
      axios.get(tools.url('/api/subcategories/34')).then((response)=>{
        this.subcategoriesmexico = response.data.subcategories;
      }).catch((error)=>{
      })
    }
  },

  mounted(){

    this.getSubcategoriesMexico();
    /* if(this.$route.params.id){
        this.id= this.$root._getURLID(this.$route.params.id);
        this.getProducts();
    } */

        this.selected.push(this.$root._getURLID(this.$route.params.id));

  },

  watch: {
    '$route.params.id':function(){
      this.getSubcategoriesMexico();
      var auxselected = [];
      this.first = false;
      auxselected.push(this.$root._getURLID(this.$route.params.id));
      this.selected = auxselected;
    },

    /* '$route.params.id':function(){

          this.id= this.$root._getURLID(this.$route.params.id);
          var nameurl = this.$root._getURLName(this.$route.params.id);

          if (nameurl != 'todas') {
              this.pagination.currentpage = 1;
              this.pagination.total = 1;
              this.pagination.total_products = 1;
              this.pagination.number = 10;

              this.getProducts();
          }
          else{
            this.pagination.currentpage = 1;
              this.pagination.total = 1;
              this.pagination.total_products = 1;
              this.pagination.number = 10;
              this.getProductsAll(this.id);
          }

      },
    'id':function(val){

          var indx = null;
          var isall = false;
          for (var i = 0; i < this.subcategories.length; i++) {
              if(this.subcategories[i].id == val){
                  indx = i;
                  if (this.subcategories[i].name == 'Todas') {
                      isall = true;
                  }
                  break;
              }
          }

          if (isall == false) {
              this.$router.push('/subcategoria/'+this.$root._converToURL(this.subcategories[indx].name, this.subcategories[indx].id));
          }
          else{
              this.$router.push('/subcategoria/'+this.$root._converToURL('todas', this.subcategories[indx].id));

          }


      },*/
      '$route.query.page':function(val){

        if (val) {
            this.pagination.currentpage = val;

        }
        else{
            this.pagination.currentpage = 1;
        }

        this.getSubcatInfo();
      },
      'selected':function(val){
          this.getSubcatInfo();

      },
      'selectedall':function(val){
        if (val == true) {
            var newselected = [];
            for (let c = 0; c < this.subcategories.length; c++) {
              newselected.push(this.subcategories[c]['id']);
              console.log('ee');
            }
            this.selected = newselected;
        }
        else{

        }


      }
    }
}
</script>
