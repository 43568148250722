<template lang="html">
  <div id="user-access-page" class="placed-backg">

    <div class="container">
      <div class="row mx-0 flex-center-xy page-size">

        <div class="form-container">
          <div class="box-color"><i><u class="fas fa-user"></u></i></div>

          <b-form class="form" @submit.prevent="recoverPassword()">

            <h1 class="mb-3">Recuperar contraseña</h1>

            <p class="mb-3">
              Escriba su correo electrónico. Le enviaremos un mensaje con los pasos para recuperar su contraseña.
            </p>

            <b-form-group>
              <b-form-input type="email"
                            v-model="form.email"
                            required
                            placeholder="Correo electrónico">
              </b-form-input>
            </b-form-group>

            <p class="mb-3">
              <router-link to="/login">¿Recuerda su contraseña? ¡Acceder!</router-link>
            </p>

            <b-form-group>
              <b-button type="submit" class="btn-blue w-100" variant="primary">Recuperar contraseña</b-button>
            </b-form-group>

          </b-form>
        </div>

      </div>
    </div>
    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block" ref="modal">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        {{modal.msg}}
        <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
        <b-button class="btn btn-success" slot="button" v-on:click.prevent="$refs.modal.close();">Accepar</b-button>
        </div>
    </sweet-modal>

  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        email: '',
        password: ''
      },
      modal:{
        msg:'',
        icon:'',
        block:false,
      },
    }
  },

  methods: {
    recoverPassword(){
      this.modal.icon = "";
      this.modal.msg = 'Loading...';
      this.modal.block = true;
      this.$refs.modal.open();

      axios.post(tools.url("/api/resetpassword"), this.form).then((response)=>{
        this.modal.block = false;
            this.modal.icon = "success";
            this.modal.msg = 'Se ha enviado un correo con tu contraseña temporal';

      }).catch((error)=>{
              this.modal.icon = "error";
              this.modal.msg = error.response.data.msg;
              this.modal.block = false;

      });
    }
  }
}
</script>
